var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useMediaQuery, Typography, Grid, FormControl, Select, MenuItem, } from '@mui/material';
import { useTranslate, } from "react-admin";
export default function PaginationControlled(_a) {
    var searchFilterGroup = _a.searchFilterGroup, total = _a.total, setTotal = _a.setTotal, count = _a.count, setCount = _a.setCount, rowsPerPage = _a.rowsPerPage, setRowsPerPage = _a.setRowsPerPage;
    // const [total, setTotal] = React.useState(25); // total
    // const [count, setCount] = React.useState(1); // page: <- 1 2 3 ... -> //Math.ceil(total / rowsPerPage)
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    var _b = React.useState([5, 10, 25, 50]), rowsPerPageOptions = _b[0], setRowsPerPageOptions = _b[1];
    var translate = useTranslate();
    // if is tab screen, change the UI (to match the defualt react-admin data table UI)
    var isTabScreen = useMediaQuery("(max-width: 900px)");
    var handleChange = function (event, value) {
        setCount(value);
        searchFilterGroup(value, rowsPerPage);
    };
    var handleChangeRowsPerPage = function (event) {
        var newCount = Math.ceil(total / event.target.value);
        var newRowsPerPage = isTabScreen ? 10 : event.target.value;
        setRowsPerPage(newRowsPerPage);
        // setCount(newCount)
        setCount(1); //set to page 1
        searchFilterGroup(1, newRowsPerPage);
    };
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "flex-end", spacing: 10, alignItems: "center", backgroundColor: "", children: [!isTabScreen && _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", paddingRight: "1rem", children: [_jsx(Typography, { variant: "body1", children: translate("pagniation.rowsPerPage") }), _jsx(RowsPerPageDropDown, { handleChangeRowsPerPage: handleChangeRowsPerPage, rowsPerPageOptions: rowsPerPageOptions, rowsPerPage: rowsPerPage })] }), _jsx(Typography, { paddingRight: "1rem", children: "".concat(((count * rowsPerPage) - rowsPerPage) + 1, "-\n            ").concat(count * rowsPerPage < total ? count * rowsPerPage : total, " ").concat(translate("pagniation.of"), " ").concat(total) }), _jsx(Pagination, { count: Math.ceil(total / rowsPerPage), page: count, onChange: handleChange, renderItem: function (item) {
                    return (_jsxs(_Fragment, { children: [(isTabScreen && (item.type == "previous" || item.type == "next")) &&
                                _jsx(PaginationItem, __assign({}, item)), !isTabScreen &&
                                _jsx(PaginationItem, __assign({}, item))] }));
                } })] }));
}
var RowsPerPageDropDown = function (_a) {
    var handleChangeRowsPerPage = _a.handleChangeRowsPerPage, rowsPerPageOptions = _a.rowsPerPageOptions, rowsPerPage = _a.rowsPerPage;
    return (_jsx("div", { children: _jsx(FormControl, { variant: "outlined", sx: { m: 1, minWidth: 40, padding: 0, margin: 0, borderBottom: "none", paddingLeft: "1rem" }, children: _jsx(Select
            // labelId="demo-simple-select-autowidth-label"
            , { 
                // labelId="demo-simple-select-autowidth-label"
                id: "demo-simple-select", value: rowsPerPage, sx: {
                    borderRadius: 0,
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                }, onChange: handleChangeRowsPerPage, 
                // autoWidth
                // sx={{ padding: 0 }}
                label: "", children: rowsPerPageOptions.map(function (el, idx) { return (_jsx(MenuItem, { value: el, children: el })); }) }) }) }));
};
