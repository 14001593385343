var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Form } from "react-admin";
import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import enUS from 'date-fns/locale/en-US';
import zhCN from 'date-fns/locale/zh-CN';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
var SubjectsDatePicker = function (_a) {
    var _b, _c, _d;
    var filters = _a.filters, setFilters = _a.setFilters, name = _a.name, label = _a.label, minWidth = _a.minWidth, minDate = _a.minDate, maxDate = _a.maxDate, size = _a.size, notNeedRequired = _a.notNeedRequired, gridBGColor = _a.gridBGColor, width = _a.width;
    var gridStyle = { marginY: "1rem" };
    if (gridBGColor) {
        gridStyle["backgroundColor"] = gridBGColor;
        gridStyle["padding"] = "0.6rem";
        gridStyle["borderRadius"] = "10px";
    }
    return (_jsx(Grid, { item: true, style: gridStyle, children: _jsx(Form, { children: _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: ((_b = JSON.parse(localStorage.getItem('auth'))) === null || _b === void 0 ? void 0 : _b.language) === 'en' ? enUS : zhCN, children: _jsx(DatePicker, { label: label, value: (filters === null || filters === void 0 ? void 0 : filters[name]) ? new Date(filters[name]) : null, onChange: function (newValue) {
                        var newFilter = __assign({}, filters);
                        if (!newValue) {
                            delete newFilter[name];
                        }
                        else {
                            // Format date as yyyy-mm-dd
                            var year = newValue.getFullYear();
                            var month = String(newValue.getMonth() + 1).padStart(2, '0');
                            var day = String(newValue.getDate()).padStart(2, '0');
                            newFilter[name] = "".concat(year, "-").concat(month, "-").concat(day);
                        }
                        setFilters(newFilter);
                        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(newFilter));
                    }, minDate: new Date("1980-01-01"), slotProps: {
                        textField: {
                            variant: "outlined",
                            placeholder: ((_c = JSON.parse(localStorage.getItem('auth'))) === null || _c === void 0 ? void 0 : _c.language) === 'en' ? 'Select date' : '選擇日期',
                            InputProps: {
                                readOnly: true
                            },
                            sx: {
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                    height: "2.93em",
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "20px",
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "black",
                                    },
                                    "& input": {
                                        paddingTop: "1.2em",
                                        fontWeight: 900,
                                        fontSize: "0.9em",
                                        cursor: "default",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                    fontSize: "0.8em",
                                    paddingTop: "0.23em",
                                    fontWeight: "900",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                },
                            }
                        },
                        actionBar: {
                            actions: ['clear']
                        }
                    }, localeText: {
                        clearButtonLabel: ((_d = JSON.parse(localStorage.getItem('auth'))) === null || _d === void 0 ? void 0 : _d.language) === 'en' ? 'Clear' : '清除'
                    }, format: "yyyy-MM-dd" // Add this line to specify the format
                 }) }) }) }));
};
export default SubjectsDatePicker;
