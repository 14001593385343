var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @ts-nocheck
import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
// Ref: https://github.com/marmelab/react-admin/blob/master/packages/ra-data-simple-rest/src/index.ts
var READ = 'GET';
var UPDATE = 'PATCH';
var CREATE = 'POST';
var DELETE = 'DELETE';
var DEFAULT_COUNT_HEADER = 'Content-Range';
// let cachedToken = JSON.parse(localStorage.getItem('accessToken')) ?? {}
var cachedToken = localStorage.getItem('accessToken');
var headerOptions = {
    headers: new Headers({
        'Authorization': "Bearer ".concat(cachedToken)
    }),
};
var SenRestProvider = function (apiUrl, countHeader, httpClient) {
    if (countHeader === void 0) { countHeader = DEFAULT_COUNT_HEADER; }
    if (httpClient === void 0) { httpClient = fetchUtils.fetchJson; }
    return ({
        getList: function (resource, params) {
            var _a;
            var _b = params.pagination, page = _b.page, perPage = _b.perPage;
            // localStorage.getItem("myCat")
            var _c = params.sort, field = _c.field, order = _c.order;
            var rangeStart = (page - 1) * perPage;
            var rangeEnd = page * perPage - 1;
            //get filters from sessionstorage
            var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
            var query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([rangeStart, rangeEnd]),
                filter: JSON.stringify(sessonStorageJSON), //[back][0715] resume this when api ready
                // filter: JSON.stringify({}),
            };
            var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
            // const url = `${apiUrl}/${resource}`; //[back] previously using this on my own
            var options = {
                headers: new Headers({
                    Range: "".concat(resource, "=").concat(rangeStart, "-").concat(rangeEnd),
                    // 'Token': `${cachedToken}`
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            };
            return httpClient(url, options).then(function (_a) {
                var _b;
                var headers = _a.headers, json = _a.json;
                // if (!headers.has(countHeader)) {
                //     throw new Error(
                //         `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                //     );
                // }
                return {
                    data: json,
                    total: countHeader === DEFAULT_COUNT_HEADER
                        ? parseInt((_b = headers.get(DEFAULT_COUNT_HEADER.toLocaleLowerCase())) === null || _b === void 0 ? void 0 : _b.split('/').pop(), 10)
                        : parseInt(headers.get(countHeader.toLowerCase())),
                };
            });
        },
        getListNoFilter: function (resource, params) {
            var _a = params.pagination, page = _a.page, perPage = _a.perPage;
            var _b = params.sort, field = _b.field, order = _b.order;
            var rangeStart = (page - 1) * perPage;
            var rangeEnd = page * perPage - 1;
            var query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([rangeStart, rangeEnd]),
                filter: JSON.stringify(params.filter),
                // filter: JSON.stringify({}),
            };
            var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
            // const url = `${apiUrl}/${resource}`; //[back] previously using this on my own
            var options = countHeader === DEFAULT_COUNT_HEADER
                ? {
                    headers: new Headers({
                        Range: "".concat(resource, "=").concat(rangeStart, "-").concat(rangeEnd),
                        'Authorization': "Bearer ".concat(cachedToken)
                    }),
                }
                : {};
            return httpClient(url, options).then(function (_a) {
                var _b;
                var headers = _a.headers, json = _a.json;
                // if (!headers.has(countHeader)) {
                //     throw new Error(
                //         `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                //     );
                // }
                return {
                    data: json,
                    total: countHeader === DEFAULT_COUNT_HEADER
                        ? parseInt((_b = headers.get(DEFAULT_COUNT_HEADER.toLocaleLowerCase())) === null || _b === void 0 ? void 0 : _b.split('/').pop(), 10)
                        : parseInt(headers.get(countHeader.toLowerCase())),
                };
            });
        },
        getOneWithoutDataJSON: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), headerOptions).then(function (_a) {
                var headers = _a.headers, json = _a.json;
                return (__assign({}, json));
            });
        },
        getOne: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), headerOptions).then(function (_a) {
                var headers = _a.headers, json = _a.json;
                return ({
                    data: json,
                });
            });
        },
        getOneExtend: function (resource, id, extResource) {
            // httpClient(`${apiUrl}/${resource}/${params.id}`, {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(id, "/").concat(extResource), {
                method: CREATE,
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var headers = _a.headers, json = _a.json;
                return ({ data: json });
            });
        },
        getOneAttribute: function (resource, parentGivenId, givenId) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(parentGivenId, "/").concat(givenId), headerOptions).then(function (_a) {
                var headers = _a.headers, json = _a.json;
                return ({
                    data: json,
                });
            });
        },
        getMany: function (resource, params) {
            var query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
            return httpClient(url, headerOptions).then(function (_a) {
                var headers = _a.headers, json = _a.json;
                return ({ data: json });
            });
        },
        getManyReference: function (resource, params) {
            var _a;
            var _b = params.pagination, page = _b.page, perPage = _b.perPage;
            var _c = params.sort, field = _c.field, order = _c.order;
            var rangeStart = (page - 1) * perPage;
            var rangeEnd = page * perPage - 1;
            var query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(__assign(__assign({}, params.filter), (_a = {}, _a[params.target] = params.id, _a))),
            };
            var url = "".concat(apiUrl, "/").concat(resource, "?").concat(stringify(query));
            var options = countHeader === DEFAULT_COUNT_HEADER
                ? {
                    headers: new Headers({
                        Range: "".concat(resource, "=").concat(rangeStart, "-").concat(rangeEnd),
                        'Authorization': "Bearer ".concat(cachedToken)
                    }),
                }
                : {};
            return httpClient(url, options).then(function (_a) {
                var _b;
                var headers = _a.headers, json = _a.json;
                if (!headers.has(countHeader)) {
                    throw new Error("The ".concat(countHeader, " header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ").concat(countHeader, " in the Access-Control-Expose-Headers header?"));
                }
                return {
                    data: json,
                    total: countHeader === DEFAULT_COUNT_HEADER
                        ? parseInt((_b = headers.get(DEFAULT_COUNT_HEADER.toLowerCase())) === null || _b === void 0 ? void 0 : _b.split('/').pop(), 10)
                        : parseInt(headers.get(countHeader.toLowerCase())),
                };
            });
        },
        update: function (resource, params) {
            // httpClient(`${apiUrl}/${resource}/${params.id}`, {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(params.data.givenId), {
                method: UPDATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        updateOne: function (resource, params, id) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(id), {
                method: UPDATE,
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        // for update attribute tables
        updateAttribute: function (resource, params, parentGivenId, givenId) {
            // httpClient(`${apiUrl}/${resource}/${params.id}`, {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(parentGivenId, "/").concat(givenId), {
                method: UPDATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        updateMany: function (resource, params) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(id), {
                    method: UPDATE,
                    body: JSON.stringify(params.data),
                    headers: new Headers({
                        'Authorization': "Bearer ".concat(cachedToken)
                    }),
                });
            })).then(function (responses) { return ({ data: responses.map(function (_a) {
                    var json = _a.json;
                    return json.id;
                }), headers: headers }); });
        },
        create: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/"), {
                method: CREATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        createOne: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource), {
                method: CREATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        createAttribute: function (resource, params, parentGivenId, givenId) {
            // httpClient(`${apiUrl}/${resource}/${params.id}`, {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(parentGivenId, "/").concat(givenId), {
                method: CREATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        delete: function (resource, params) {
            var _a;
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat((_a = params === null || params === void 0 ? void 0 : params.data) === null || _a === void 0 ? void 0 : _a.givenId), {
                method: DELETE,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        deleteAttribute: function (resource, params, parentGivenId, givenId) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(parentGivenId, "/").concat(givenId), {
                method: DELETE,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        deleteMany: function (resource, params) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(id), {
                    method: DELETE,
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                        'Authorization': "Bearer ".concat(cachedToken)
                    }),
                });
            })).then(function (responses) { return ({
                data: responses.map(function (_a) {
                    var json = _a.json, headers = _a.headers;
                    return json.id;
                }),
            }); });
        },
        fpPdfUpload: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/pdf/").concat(params.data.parentGivenId, "/").concat(params.data.givenId), {
                method: UPDATE,
                headers: new Headers({
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: params.data.formData,
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        fpUpload: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/fpc/").concat(params.data.parentGivenId, "/").concat(params.data.givenId), {
                method: CREATE,
                headers: new Headers({
                    //     'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: params.data.formData,
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        fpOneUpdate: function (resource, params, givenId) {
            return httpClient("".concat(apiUrl, "/fp/one/").concat(givenId), {
                method: UPDATE,
                headers: new Headers({
                    //     'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        fpClassify: function (params) {
            return httpClient("".concat(apiUrl, "/exams/gradingImages"), {
                method: CREATE,
                headers: new Headers({
                    //     'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: JSON.stringify(params),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        fpOneDelete: function (resource, givenId) {
            return httpClient("".concat(apiUrl, "/fp/one/").concat(givenId), {
                method: DELETE,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        fpOneGet: function (resource, parentId, givenId) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(parentId, "/").concat(givenId), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        triggerExamAnalysis: function (params) {
            return httpClient("".concat(apiUrl, "/exams/triggerAnalysis"), {
                method: CREATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        dataExport: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/data/export"), {
                method: CREATE,
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        // login/pw related
        changePW: function (params) {
            return httpClient("".concat(apiUrl, "/auth/changePW"), {
                method: UPDATE,
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        /* dashboard */
        totalSubjects: function (resource) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/count/subject/created"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        totalSubjectsOrg: function (resource) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/count/subject/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        totalSubjectsRep: function (resource) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/count/report/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        totalExams: function (resource) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/count/exam/created"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        totalExamsOrg: function (resource) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/count/exam/created/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        FinishedExams: function (resource) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/count/exam/finished/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        /*   your org left bar chart */
        UrOrgleftbar: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/highRisk/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        /* your org pie chart */
        UrOrgPie1: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/finishedExam/confidence/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        UrOrgPie2: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/finishedExam/eye/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        UrOrgPie3: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/finishedExam/AIModel/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        UrOrgPie4: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/gender/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        UrOrgPie5: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/eduLevel/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        UrOrgPie6: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/age/org"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        /* All Org  */
        Card1: function () {
            return httpClient("".concat(apiUrl, "/dashboard/count/subject/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        Card2: function () {
            return httpClient("".concat(apiUrl, "/dashboard/count/exam/created/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        Card4: function () {
            return httpClient("".concat(apiUrl, "/dashboard/count/exam/finished/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        /*   All org left bar */
        AllOrgleftbar: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/highRisk/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        /* All org pie chart */
        AllOrgPie1: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/finishedExam/confidence/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        AllOrgPie2: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/finishedExam/eye/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        AllOrgPie3: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/finishedExam/AIModel/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        AllOrgPie4: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/gender/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        AllOrgPie5: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/edulevel/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        AllOrgPie6: function () {
            return httpClient("".concat(apiUrl, "/dashboard/distribution/subject/age/all"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        ShowUrOrg: function (params) {
            return httpClient("".concat(apiUrl, "/users/").concat(params.id), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        GetProducts: function () {
            return httpClient("".concat(apiUrl, "/payment/products"), {
                method: READ,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        DeleteProduct: function (key) {
            return httpClient("".concat(apiUrl, "/payment/products/").concat(key), {
                method: DELETE,
                headers: new Headers({
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        CreatePackage: function (resource, params) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/"), {
                method: CREATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        EditPackage: function (resource, params) {
            // httpClient(`${apiUrl}/${resource}/${params.id}`, {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/").concat(params.key), {
                method: UPDATE,
                body: JSON.stringify(params.data),
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
        DisableUser: function (resource, params, id) {
            return httpClient("".concat(apiUrl, "/").concat(resource, "/disable/").concat(id), {
                method: UPDATE,
                headers: new Headers({
                    'Authorization': "Bearer ".concat(cachedToken)
                }),
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                var json = _a.json, headers = _a.headers;
                return ({ data: json });
            });
        },
    });
};
export default SenRestProvider;
