var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Grid } from '@mui/material';
import { SubscriptionBar, SubscriptionCard } from '../helper/PackageComps';
import { loadStripe } from '@stripe/stripe-js';
var stripePromise = loadStripe("pk_test_ju6veMmqd5eDMe1XhQVPyze2");
var PackageComp = function (_a) {
    var params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters, handleSubscribe = _a.handleSubscribe, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, openEditDialog = _a.openEditDialog, reloadRecord = _a.reloadRecord, closeDialog = _a.closeDialog, acl = _a.acl, subCard = _a.subCard, setSubCard = _a.setSubCard, fetchPackageData = _a.fetchPackageData;
    var dataProvider = useDataProvider();
    useEffect(function () {
        fetchPackageData();
    }, [dataProvider]);
    var notify = useNotify();
    var refresh = useRefresh();
    var handleControlLabel = function (e) {
        setFilters(__assign(__assign({}, filters), { security: e.target.checked }));
    };
    var deleteProduct = function (key) {
        dataProvider.DeleteProduct(key).then(function () {
            notify("Package deleted");
            setSubCard(subCard.filter(function (product) { return product.key !== key; })); // no need to refresh, you can immediately remove the product from the list
        });
    };
    return (_jsx(_Fragment, { children: filters &&
            _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "start", children: [_jsx(SubscriptionBar, { gens: filters === null || filters === void 0 ? void 0 : filters.gens, translate: translate }), _jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", xs: 12, marginY: 2, gap: 1, children: Array.isArray(subCard) && subCard.map(function (el, idx) {
                            return (_jsx(SubscriptionCard, { translate: translate, el: el, idx: idx, handleSubscribe: handleSubscribe, isLoading: isLoading, setIsLoading: setIsLoading, deleteProduct: deleteProduct, openEditDialog: openEditDialog, reloadRecord: reloadRecord, closeDialog: closeDialog, acl: acl }));
                        }) })] }) }));
};
export default PackageComp;
