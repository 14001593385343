// @ts-nocheck
var turnDateToChinaStdTime = function (dateString) {
    var date = new Date(dateString);
    var validDate = !isNaN(date.getTime());
    var options;
    var formattedDate;
    // if valid date, return date. else return today's date
    if (validDate) {
        options = {
            timeZone: 'Asia/Shanghai',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        formattedDate = date.toLocaleDateString('en-US', options)
            .replace(/\//g, '/');
    }
    else {
        formattedDate = turnDateToChinaStdTime(getTodaysDateChinaTimeZone());
    }
    return formattedDate;
};
var changeFromMMDDYYYYToYYYYMMDD = function (dateString, format) {
    // given string: MM/DD/YYYY; expected outcome: YYYY/MM/DD
    var splitDateString = dateString.split('/');
    var month = splitDateString[0];
    var day = splitDateString[1];
    if (month.length === 1)
        month = "0".concat(splitDateString[0]);
    if (day.length === 1)
        day = "0".concat(splitDateString[1]);
    var formattedDate;
    switch (format) {
        case ('slideFormat'): //yyyy/mm/dd
            formattedDate = "".concat(splitDateString[2], "/").concat(splitDateString[0], "/").concat(splitDateString[1]);
            break;
        case ('noFormat'): //yyyymmdd
            formattedDate = "".concat(splitDateString[2]).concat(month).concat(day);
            break;
        default: //yyyy-mm-dd
            formattedDate = "".concat(splitDateString[2], "-").concat(month, "-").concat(day);
    }
    return formattedDate;
};
function getTodaysDateChinaTimeZone() {
    // get todays date in china standard time
    var options = { timeZone: 'Asia/Shanghai' };
    var formattedDate = new Date().toLocaleString('en-US', options);
    return formattedDate;
}
function defaultDateOfBirth() {
    var formattedDate = new Date("1980/01/01").toLocaleString('en-US');
    return formattedDate;
}
function getSessionDateOrTodaysDate() {
    var initcurrentChoseDateTime = sessionStorage.getItem("".concat(window.location.pathname, "_date_filter"));
    // if user set date
    if (initcurrentChoseDateTime) {
        initcurrentChoseDateTime = JSON.parse(initcurrentChoseDateTime);
        initcurrentChoseDateTime = turnDateToChinaStdTime(new Date(initcurrentChoseDateTime['date']));
    }
    else {
        initcurrentChoseDateTime = turnDateToChinaStdTime(getTodaysDateChinaTimeZone());
    }
    return initcurrentChoseDateTime;
}
function getSessionRangeDateOrTodaysDate() {
    var initcurrentChoseDateTime = sessionStorage.getItem("".concat(window.location.pathname, "_range_date_filter"));
    // if user set "from" & "to" date previously
    if (initcurrentChoseDateTime) {
        initcurrentChoseDateTime = JSON.parse(initcurrentChoseDateTime)['date'];
        // if user set "from" date
        if (initcurrentChoseDateTime['from'] & !initcurrentChoseDateTime['to']) {
            initcurrentChoseDateTime['to'] = initcurrentChoseDateTime['from'];
        }
        // if user set "to" date 
        else if (!initcurrentChoseDateTime['from'] & initcurrentChoseDateTime['to']) {
            initcurrentChoseDateTime['from'] = initcurrentChoseDateTime['to'];
        }
        // disallow "to" bigger than "from"
        if (new Date(initcurrentChoseDateTime['to']) < new Date(initcurrentChoseDateTime['from'])) {
            initcurrentChoseDateTime['to'] = initcurrentChoseDateTime['from'];
        }
    }
    // if user didnt set "from" & "to" date, add default - todays date for "from" and "to"
    else {
        var todaysDate = turnDateToChinaStdTime(getTodaysDateChinaTimeZone(), true);
        initcurrentChoseDateTime = { from: todaysDate, to: todaysDate };
    }
    return initcurrentChoseDateTime;
}
function add8Hours(dateString) {
    var date = new Date(dateString);
    date.setHours(date.getHours() + 8);
    var newDateString = date.toISOString();
    return newDateString;
}
function minus8Hours(dateString) {
    var date = new Date(dateString);
    date.setHours(date.getHours() - 8);
    var newDateString = date.toString();
    return newDateString;
}
function getDDorMMorYYYY(dateString, requirement) {
    var splitDateString = dateString.split('/');
    switch (requirement) {
        case "day":
            return splitDateString[1];
            break;
        case "month":
            return splitDateString[0];
            break;
        default:
            return splitDateString[2];
    }
}
function getMonthTotalDays(dateString) {
    var _a = dateString.split('-'), year = _a[0], month = _a[1]; // Split the date string into year and month parts
    var totalDays = new Date(year, month, 0).getDate();
    return totalDays;
}
function getLastMonthTotalDays(thisMonthDateString) {
    var _a = thisMonthDateString.split('-'), year = _a[0], month = _a[1], day = _a[2]; // Split the date string into year, month, and day parts
    // Create a new Date object for the given date
    var currentDate = new Date(year, month - 1, day);
    // Subtract one month from the current date
    currentDate.setMonth(currentDate.getMonth() - 1);
    // Format the last month date as "yyyy/mm/dd"
    var lastMonth = "".concat(currentDate.getFullYear(), "-").concat((currentDate.getMonth() + 1).toString().padStart(2, '0'), "-").concat(currentDate.getDate().toString().padStart(2, '0'));
    return getMonthTotalDays(lastMonth);
}
function isCurrentMonthAndYear(dateString) {
    var date = new Date(dateString);
    var currentDate = new Date();
    // Compare year and month
    return (date.getFullYear() === currentDate.getFullYear() &&
        date.getMonth() === currentDate.getMonth());
}
function isCurrentYear(dateString) {
    var date = new Date(dateString);
    var currentDate = new Date();
    // Compare year and month
    return (date.getFullYear() === currentDate.getFullYear());
}
function getMonth(month) {
    switch (month) {
        case 0:
            return 12;
            break;
        default:
            return month;
            break;
    }
}
function getYearFromLastMonth(dateString) {
    var dateStringYear = new Date(dateString).getFullYear();
    var dateStringMonth = new Date(dateString).getMonth();
    switch (true) {
        // if is first month, always return given date string year - 1
        case dateStringMonth === 0:
            return dateStringYear - 1;
            break;
        default:
            return dateStringYear;
    }
}
export { turnDateToChinaStdTime, getTodaysDateChinaTimeZone, getSessionDateOrTodaysDate, getSessionRangeDateOrTodaysDate, changeFromMMDDYYYYToYYYYMMDD, add8Hours, minus8Hours, getDDorMMorYYYY, getMonthTotalDays, getLastMonthTotalDays, isCurrentMonthAndYear, isCurrentYear, getMonth, getYearFromLastMonth, defaultDateOfBirth, };
