var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React, { useEffect } from 'react';
import { Card, CardHeader, ListItemButton, ListItemText, ListItemIcon, Box, Grid, Zoom, Typography, List, Button, Checkbox, Divider, Dialog, DialogContent, DialogActions, useMediaQuery, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { editComp, dialog, } from '../../../utils/componentWizard';
import { subjectIdHelper } from '../../../utils/helper';
import popupStyles from "../../../styles/popup/popup.module.css";
var Spacer = function () { return _jsx(Box, { mb: 1, children: "\u00A0" }); };
function not(a, b) {
    return a.filter(function (value) { return b.indexOf(value) === -1; });
}
function origNot(a, b) {
    return a.filter(function (origItem) {
        return !b.some(function (checkedItem) { return checkedItem.id === origItem.id; });
    });
}
function intersection(a, b) {
    return a.filter(function (value) { return b.indexOf(value) !== -1; });
}
function union(a, b) {
    return __spreadArray(__spreadArray([], a, true), not(b, a), true);
}
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 3,
            },
        },
    }
};
var UserTransferList = function (_a) {
    var translate = _a.translate, searchFilterGroup = _a.searchFilterGroup, opened = _a.opened, closeDialog = _a.closeDialog, selectedViewOrgName = _a.selectedViewOrgName, dialogData = _a.dialogData, allUserData = _a.allUserData, handleSave = _a.handleSave;
    var _b = React.useState([]), checked = _b[0], setChecked = _b[1];
    var _c = React.useState([]), origLeft = _c[0], setOrigLeft = _c[1]; //available users - original
    var _d = React.useState([]), origRight = _d[0], setOrigRight = _d[1]; // usergroup users  - original
    var _e = React.useState([]), left = _e[0], setLeft = _e[1]; //available users  - searched ones
    var _f = React.useState([]), right = _f[0], setRight = _f[1]; // usergroup users - searched ones
    var _g = React.useState({ left: "", right: "" }), searchText = _g[0], setSearchText = _g[1]; // usergroup users - searched ones
    var leftChecked = intersection(checked, left);
    var rightChecked = intersection(checked, right);
    function checkFirstElement(arr, id) {
        return arr.length > 0 && arr[0].includes(id);
    }
    //update the arrays
    function handleSearch(value, name) {
        var newFilteredList = [];
        switch (true) {
            // left - empty imput
            case (!value && name == "left"):
                var copiedOrigLeft = __spreadArray([], origLeft, true);
                setLeft(copiedOrigLeft);
                break;
            //left - has imput
            case (value && name == "left"):
                setLeft([]);
                newFilteredList = left.filter(function (obj) {
                    return Object.values(obj).some(function (item) { return item.includes(value); });
                });
                setLeft(newFilteredList);
                break;
            // right - empty imput
            case (!value && name == "right"):
                var copiedOrigRight = __spreadArray([], origRight, true);
                setRight(copiedOrigRight);
                break;
            //right - has imput
            case (value && name == "right"):
                setRight([]);
                newFilteredList = right.filter(function (obj) {
                    return Object.values(obj).some(function (item) { return item.includes(value); });
                });
                setRight(newFilteredList);
                break;
        }
    }
    function handleSearchText(value, name) {
        var newSearchText = __assign({}, searchText);
        newSearchText[name] = value;
        handleSearch(value, name);
        setSearchText(newSearchText);
    }
    useEffect(function () {
        //dialogData is for "Group" dialog data
        var curGroupId = dialogData.id;
        var newLeft = [];
        var newRight = [];
        // set up new left and right
        allUserData.map(function (el, idx) {
            var includesId = false;
            el.userGroups.map(function (usgEl, usgIdx) {
                if (usgEl.id === curGroupId) {
                    includesId = true;
                }
                ;
            });
            if (includesId) {
                newRight.push({ id: el.id, username: el.username, name: "".concat(el.Last_name, " ").concat(el.First_name), email: el.email });
            }
            else {
                newLeft.push({ id: el.id, username: el.username, name: "".concat(el.Last_name, " ").concat(el.First_name), email: el.email });
            }
        });
        setLeft(newLeft);
        setOrigLeft(newLeft);
        setRight(newRight);
        setOrigRight(newRight);
    }, []);
    //[back][0904] handleToggle needs to remove/push the origArr after moved to other side
    var handleToggle = function (value) { return function () {
        var currentIndex = checked.indexOf(value);
        var newChecked = __spreadArray([], checked, true);
        if (currentIndex === -1) {
            newChecked.push(value);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    }; };
    var numberOfChecked = function (items) { return intersection(checked, items).length; };
    var handleToggleAll = function (items) { return function () {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        }
        else {
            setChecked(union(checked, items));
        }
    }; };
    var handleCheckedRight = function () {
        setOrigRight(origRight.concat(leftChecked));
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setOrigLeft(origNot(origLeft, leftChecked));
        setChecked(not(checked, leftChecked));
        //remove from origLeft
    };
    var handleCheckedLeft = function () {
        setOrigLeft(origLeft.concat(rightChecked));
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setOrigRight(origNot(origRight, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    var CustomList = function (title, items, selectedViewOrgName, sideName) { return (_jsxs(Card, { style: { border: "1px solid lightgrey", borderRadius: "20px" }, children: [_jsx(CardHeader, { sx: { px: 2, py: 1 }, avatar: _jsx(Checkbox, { onClick: handleToggleAll(items), checked: numberOfChecked(items) === items.length && items.length !== 0, indeterminate: numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0, disabled: items.length === 0, inputProps: {
                        'aria-label': 'all items selected',
                    } }), title: _jsx(Typography, { variant: "h6", paddingBottom: 0, fontWeight: "bold", children: "".concat(title, " ").concat(selectedViewOrgName !== null && selectedViewOrgName !== void 0 ? selectedViewOrgName : "") }), subheader: _jsxs(Typography, { paddingX: 0, children: [" ", "".concat(numberOfChecked(items), "/").concat(items.length, " ").concat(translate("pages.organizations.selected"))] }) }), _jsx(Divider, {}), _jsx(Grid, { item: true, margin: 1, paddingX: 1, children: _jsx(editComp.searchTextField, { fullWidth: true, onChange: handleSearchText, name: sideName, placeholder: "username" }) }), _jsxs(List, { sx: {
                    // width: 500,
                    height: 300,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }, dense: true, component: "div", role: "list", children: [!isMobile && _jsx(ListItemButton, { role: "listitem", children: _jsxs(Grid, { container: true, sm: 12, display: "flex", flexWrap: "nowrap", alignItems: "start", children: [_jsx(Grid, { item: true, sm: 1, marginX: 0 }), _jsx(Grid, { item: true, sm: 1, marginTop: 1, marginX: 0, align: 'center', children: _jsx(ListItemText, { primary: "#" }) }), _jsx(Grid, { item: true, sm: 1.5, marginTop: 1, marginX: 0, children: _jsx(ListItemText, { primary: translate("pages.settings.fields.profile.info.id") }) }), _jsx(Grid, { item: true, sm: 3, marginTop: 1, marginX: 0, style: { wordBreak: "break-word" }, children: _jsx(ListItemText, { primary: translate("pages.settings.fields.profile.info.name") }) }), _jsx(Grid, { item: true, sm: 5.5, marginTop: 1, marginX: 0, style: { wordBreak: "break-word" }, children: _jsx(ListItemText, { primary: translate("pages.settings.fields.profile.info.email") }) })] }) }), items === null || items === void 0 ? void 0 : items.map(function (value, idx) {
                        var labelId = "transfer-list-all-item-".concat(idx, "-label");
                        return (_jsx(Grid, { backgroundColor: idx % 2 == 0 ? "WhiteSmoke" : "white", borderTop: '2px solid LightGrey', children: _jsx(ListItemButton, { role: "listitem", onClick: handleToggle(value), children: _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "start", children: [_jsx(Grid, { item: true, sm: 1, xs: 3.5, marginLeft: isMobile ? -1.5 : 0, children: _jsx(ListItemIcon, { children: _jsx(Checkbox, { checked: checked.indexOf(value) !== -1, tabIndex: -1, disableRipple: true, inputProps: {
                                                        'aria-labelledby': labelId,
                                                    } }) }) }), _jsx(Grid, { item: true, sm: 1, xs: 8, marginTop: 1, marginLeft: isMobile ? 1.5 : 0, display: "flex", flex: 'flexwrap', children: _jsx(ListItemText, { align: isMobile ? 'left' : 'center', id: labelId, primary: isMobile ? "# " + idx : idx }) }), _jsxs(Grid, { item: true, sm: 1.5, xs: 12, marginTop: isMobile ? 0 : 1, display: "flex", flex: 'flexwrap', children: [isMobile && _jsx(Grid, { item: true, xs: 3.5, children: _jsx(ListItemText, { primary: translate("pages.settings.fields.profile.info.id") }) }), _jsx(ListItemText, { id: labelId, primary: " ".concat(subjectIdHelper(value.id)) })] }), _jsxs(Grid, { item: true, sm: 3, xs: 12, marginTop: isMobile ? 0 : 1, display: "flex", flex: 'flexwrap', style: { wordBreak: "break-word" }, children: [isMobile && _jsx(Grid, { item: true, xs: 3.5, children: _jsx(ListItemText, { primary: translate("pages.settings.fields.profile.info.name") }) }), _jsx(ListItemText, { id: labelId, primary: " ".concat(value.name) })] }), _jsxs(Grid, { container: true, sm: 5.5, xs: 12, marginTop: isMobile ? 0 : 1, display: "flex", flex: 'flexwrap', style: { wordBreak: "break-word" }, children: [isMobile && _jsx(Grid, { item: true, xs: 3.5, children: _jsx(ListItemText, { primary: translate("pages.settings.fields.profile.info.email") }) }), _jsx(Grid, { item: true, sm: 12, xs: 8.5, width: '100%', children: _jsx(ListItemText, { id: labelId, primary: " ".concat(value.email), primaryTypographyProps: { style: { noWrap: true } } }) })] })] }) }, idx) }));
                    }), items.length === 0 && (_jsx(Typography, { variant: "h6", color: "primary", sx: { textAlign: 'center', py: 2, fontWeight: 'bold' }, children: translate('pages.organizations.noUsersInOrganization') }))] })] })); };
    var isVertical = useMediaQuery("(max-width : 1200px)");
    //const isMobile = useMediaQuery("(max-width : 600px)");
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsxs(Dialog, { fullWidth: true, maxWidth: "xl", open: opened, TransitionComponent: Transition, onClose: closeDialog, className: classes.noMarginDialog, keepMounted: true, sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            '& .MuiDialog-paper': {
                borderRadius: '30px',
                backgroundColor: "#F5F5F5",
                padding: isMobile ? '0' : "0.5em",
                margin: "2rem",
                // width: "900px !important",
            },
        }, children: [_jsxs(DialogContent, { className: popupStyles.dialogContent, children: [_jsx(dialog.header, { title: "Group " + dialogData.name ? "#" + dialogData.name : "", handleClose: closeDialog }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "center", children: [_jsx(Grid, { item: true, lg: 5, md: 8, sm: 12, margin: 0, width: "100%", children: CustomList(translate("pages.organizations.availableUsersInOrg"), left, selectedViewOrgName, "left") }), _jsx(Grid, { item: true, lg: 1, md: 12, sm: 12, margin: 1, children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "nowrap", direction: "column", alignItems: "center", justifyContent: "center", sx: { height: "100%" }, children: [_jsx(Button, { sx: {
                                                my: 0.5,
                                                backgroundColor: '#2196f3', // Brighter blue
                                                color: 'white',
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: '#1565c0', // Darker blue on hover
                                                    transform: 'scale(1.1)',
                                                    boxShadow: '0 4px 8px rgba(33,150,243,0.4)',
                                                },
                                                '&:disabled': {
                                                    backgroundColor: '#bbdefb',
                                                    color: 'white',
                                                    transform: 'none',
                                                    boxShadow: 'none'
                                                }
                                            }, variant: "contained", size: "small", onClick: handleCheckedRight, disabled: leftChecked.length === 0, "aria-label": "move selected right", children: _jsx("b", { children: isVertical ? 'V' : '>' }) }), _jsx(Button, { sx: {
                                                my: 0.5,
                                                backgroundColor: '#2196f3', // Brighter blue
                                                color: 'white',
                                                transition: 'all 0.3s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: '#1565c0', // Darker blue on hover
                                                    transform: 'scale(1.1)',
                                                    boxShadow: '0 4px 8px rgba(33,150,243,0.4)',
                                                },
                                                '&:disabled': {
                                                    backgroundColor: '#bbdefb',
                                                    color: 'white',
                                                    transform: 'none',
                                                    boxShadow: 'none'
                                                }
                                            }, variant: "contained", size: "small", onClick: handleCheckedLeft, disabled: rightChecked.length === 0, "aria-label": "move selected left", children: _jsx("b", { children: isVertical ? 'Ʌ' : '<' }) })] }) }), _jsx(Grid, { item: true, lg: 5, md: 8, sm: 12, margin: 0, width: "100%", children: CustomList(translate("pages.organizations.userIn") + dialogData.name, right, null, "right") })] })] }), _jsx(DialogActions, { children: _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: _jsx(Button, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, handleSave(origRight, dialogData.id)];
                                case 1:
                                    _a.sent();
                                    closeDialog();
                                    return [2 /*return*/];
                            }
                        }); }); }, variant: "contained", color: "primary", children: translate("common.save") }) }) })] }));
};
export default UserTransferList;
