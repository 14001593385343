var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslate, useNotify, useDataProvider, useResourceContext, InfiniteList, Datagrid, FunctionField, useSidebarState, Pagination } from 'react-admin';
// MUI Components
import { Box, Card, Grid, Typography, Button, CardActions, useMediaQuery } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { BarChart } from '@mui/x-charts/BarChart';
// MUI Icons
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskIcon from '@mui/icons-material/Task';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import StarIcon from '@mui/icons-material/Star';
import globalStyles from "../styles/globalStyles";
// Charts
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { dbDateHelper } from '../utils/helper';
var YourDashboard = function (props) {
    var translate = useTranslate();
    var notify = useNotify();
    var navigate = useNavigate();
    var location = useLocation();
    var PostPagination = function () { return (_jsx(Pagination, { rowsPerPageOptions: [5, 10, 25], defaultValue: 5 })); };
    var dataProvider = useDataProvider();
    var resource = useResourceContext(props);
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        // backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
    }); };
    var _a = useState([
        { title: translate('pages.dashboard.title.totalSubjects'), subtitle: translate('pages.dashboard.title.cbu'), value: 0, label: "Total Subjects" },
        { title: translate('pages.dashboard.title.totalExams'), subtitle: translate('pages.dashboard.title.cbu'), value: 0, label: "Total Exams" },
        { title: translate('pages.dashboard.title.generatedReports'), value: 0, label: "Generated Reports" },
        { title: translate('pages.dashboard.title.advancedYearly'), subtitle: translate('pages.dashboard.title.until20241231'), value: 0, highlight: true, label: "Advanced - Yearly" },
        { title: translate('pages.dashboard.title.totalSubjects'), subtitle: translate('pages.dashboard.title.iuo'), value: 0, label: "Total Subjects" },
        { title: translate('pages.dashboard.title.totalExams'), subtitle: translate('pages.dashboard.title.iuo'), value: 0, label: "Total Exams" },
        { title: translate('pages.dashboard.title.unfinishExams'), subtitle: translate('pages.dashboard.title.iuo'), value: 0, percentage: "(0%)", label: "Unfinished Exams" },
        { title: translate('pages.dashboard.title.finishedExams'), subtitle: translate('pages.dashboard.title.iuo'), value: 0, percentage: "(0%)", label: "Finished Exams" },
    ]), cards = _a[0], setCards = _a[1];
    var _b = useState([
        { title: translate('pages.dashboard.title.barChart2'), headerColor: 'linear-gradient(135deg, #6a1b9a 0%, #9c27b0 50%, #d500f9 100%)' },
        { title: translate('pages.dashboard.title.redlist2'), headerColor: 'linear-gradient(135deg, #D32F2F 0%, #C62828 100%)' },
    ]), cards2 = _b[0], setCards2 = _b[1];
    var _c = useState([
        { title1: translate('pages.dashboard.title.pie1'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie2'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie3'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie4'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie5'), title2: translate('pages.dashboard.title.total'), },
        { title1: translate('pages.dashboard.title.pie6'), title2: translate('pages.dashboard.title.total'), },
    ]), cards3 = _c[0], setCards3 = _c[1];
    var _d = useState({ data: [] }), UrOrgleftbar = _d[0], setUrOrgleftbar = _d[1];
    var _e = useState({ data: [] }), pie1 = _e[0], setPie1 = _e[1];
    var _f = useState({ data: [] }), pie2 = _f[0], setPie2 = _f[1];
    var _g = useState({ data: [] }), pie3 = _g[0], setPie3 = _g[1];
    var _h = useState({ data: [] }), pie4 = _h[0], setPie4 = _h[1];
    var _j = useState({ data: [] }), pie5 = _j[0], setPie5 = _j[1];
    var _k = useState({ data: [] }), pie6 = _k[0], setPie6 = _k[1];
    var _l = useState({ data: [] }), barData1 = _l[0], setBarData1 = _l[1];
    var _m = useState({ data: [] }), barData2 = _m[0], setBarData2 = _m[1];
    // useEffect(() => {
    //     window.location.reload();
    // }, [])
    useEffect(function () {
        Promise.all([
            dataProvider.totalSubjects(resource),
            dataProvider.totalExams(resource),
            dataProvider.totalSubjectsRep(resource),
            dataProvider.totalSubjectsOrg(resource),
            dataProvider.totalExamsOrg(resource),
            dataProvider.FinishedExams(resource),
            dataProvider.UrOrgleftbar(resource),
            dataProvider.UrOrgPie1(resource),
            dataProvider.UrOrgPie2(resource),
            dataProvider.UrOrgPie3(resource),
            dataProvider.UrOrgPie4(resource),
            dataProvider.UrOrgPie5(resource),
            dataProvider.UrOrgPie6(resource),
        ])
            .then(function (_a) {
            var totalSubjectsResponse = _a[0], totalExamsResponse = _a[1], totalSubjectsRepResponse = _a[2], totalSubjectsOrgResponse = _a[3], totalExamsOrgResponse = _a[4], finishedExamsResponse = _a[5], UrOrgleftbarResponse = _a[6], UrOrgPie1Response = _a[7], UrOrgPie2Response = _a[8], UrOrgPie3Response = _a[9], UrOrgPie4Response = _a[10], UrOrgPie5Response = _a[11], UrOrgPie6Response = _a[12];
            setCards(function (prevCards) {
                var newCards = __spreadArray([], prevCards, true);
                newCards[0] = __assign(__assign({}, newCards[0]), { value: totalSubjectsResponse.data });
                newCards[1] = __assign(__assign({}, newCards[1]), { value: totalExamsResponse.data });
                newCards[2] = __assign(__assign({}, newCards[2]), { value: totalSubjectsRepResponse.data });
                newCards[4] = __assign(__assign({}, newCards[4]), { value: totalSubjectsOrgResponse.data });
                newCards[5] = __assign(__assign({}, newCards[5]), { value: totalExamsOrgResponse.data });
                newCards[6] = __assign(__assign({}, newCards[6]), { value: totalExamsOrgResponse.data - finishedExamsResponse.data });
                newCards[7] = __assign(__assign({}, newCards[7]), { value: finishedExamsResponse.data });
                return newCards;
            });
            setBarData1({ data: UrOrgleftbarResponse.data.data1.map(function (item) { return item.value; }) });
            setBarData2({ data: UrOrgleftbarResponse.data.data2.map(function (item) { return item.value; }) });
            setPie1(UrOrgPie1Response.data);
            //translate all pie datas
            var translatedAllOrgPie2Response = __spreadArray([], UrOrgPie2Response.data.data, true);
            translatedAllOrgPie2Response === null || translatedAllOrgPie2Response === void 0 ? void 0 : translatedAllOrgPie2Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("pages.exam.fields.params.eye.".concat(el.label));
            });
            setPie2({ data: translatedAllOrgPie2Response });
            // setPie3(AllOrgPie3Response.data)
            var translatedAllOrgPie3Response = __spreadArray([], UrOrgPie3Response.data.data, true);
            translatedAllOrgPie3Response === null || translatedAllOrgPie3Response === void 0 ? void 0 : translatedAllOrgPie3Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("pages.exam.fields.params.ai_model.".concat(el.label));
            });
            setPie3({ data: translatedAllOrgPie3Response });
            // setPie4(AllOrgPie4Response.data)
            var translatedAllOrgPie4Response = __spreadArray([], UrOrgPie4Response.data.data, true);
            translatedAllOrgPie4Response === null || translatedAllOrgPie4Response === void 0 ? void 0 : translatedAllOrgPie4Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("params.subjects.".concat(el.label));
            });
            setPie4({ data: translatedAllOrgPie4Response });
            // setPie5(AllOrgPie5Response.data)
            var translatedAllOrgPie5Response = __spreadArray([], UrOrgPie5Response.data.data, true);
            translatedAllOrgPie5Response === null || translatedAllOrgPie5Response === void 0 ? void 0 : translatedAllOrgPie5Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("params.subjects.".concat(el.label));
            });
            setPie5({ data: translatedAllOrgPie5Response });
            // setPie6(AllOrgPie6Response.data)
            var translatedAllOrgPie6Response = __spreadArray([], UrOrgPie6Response.data.data, true);
            translatedAllOrgPie6Response === null || translatedAllOrgPie6Response === void 0 ? void 0 : translatedAllOrgPie6Response.map(function (el, idx) {
                if (el.label == "N/A" || !el.label) {
                    return;
                }
                el.label = translate("params.dashboard.years.".concat(el.label));
            });
            setPie6({ data: translatedAllOrgPie6Response });
        })
            .catch(function (error) {
            console.error('Error fetching dashboard data:', error);
            notify(translate('error.dashboard.fetchData'), {
                type: 'error',
            });
        });
    }, [dataProvider]);
    var _o = useState({
        data: [
            { id: 0, value: 0, label: "0-20y" },
            { id: 1, value: 0, label: "20-40y" },
            { id: 2, value: 0, label: "40-60y" },
            { id: 3, value: 0, label: "60-80y" },
            { id: 4, value: 0, label: ">80y" },
            { id: 5, value: 0, label: "N/A" },
        ]
    }), piedata = _o[0], setPieData = _o[1];
    var pies = [pie1, pie2, pie3, pie4, pie5, pie6];
    var open = useSidebarState()[0];
    var isSmallAndHamburger = useMediaQuery("(max-width: 700px)") && open;
    var isSmallAndHamburger2 = useMediaQuery("(max-width: 850px)") && open;
    function getWindowDimensions() {
        var width = window.innerWidth, height = window.innerHeight;
        return {
            width: width,
            height: height
        };
    }
    function useWindowDimensions() {
        var _a = useState(getWindowDimensions()), windowDimensions = _a[0], setWindowDimensions = _a[1];
        useEffect(function () {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener('resize', handleResize);
            return function () { return window.removeEventListener('resize', handleResize); };
        }, []);
        return windowDimensions;
    }
    var winwidth = useWindowDimensions().width;
    return (_jsx(Box, { children: _jsxs(Box, { p: 2, children: [_jsx(Grid, { container: true, spacing: 1, marginBottom: 3, children: cards.map(function (card, index) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 3, children: _jsx(Card, { elevation: 5, style: {
                                padding: '15px',
                                background: card.highlight
                                    ? 'linear-gradient(45deg, #E69500 30%, #E6C200 90%)'
                                    : 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '20px',
                                color: 'white',
                                boxShadow: card.highlight
                                    ? '0 6px 12px rgba(235, 195, 0, 0.3)'
                                    : '0 6px 12px rgba(13, 130, 223, 0.3)',
                                transition: 'all 0.3s ease-in-out',
                                transform: 'translateY(0)',
                            }, children: _jsxs(Grid, { container: true, style: {
                                    height: '100%',
                                    display: 'flex',
                                    flexWrap: "wrap",
                                    alignItems: 'center',
                                    justifyContent: "space-between",
                                }, children: [_jsxs(Grid, { item: true, style: {
                                            width: '5em',
                                            height: '5em',
                                            // marginRight: '10px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'rgba(255, 255, 255, 0.2)',
                                            borderRadius: '50%',
                                            padding: '15px'
                                        }, children: [card.label === "Total Subjects" && _jsx(PeopleIcon, { style: { fontSize: '50px', color: 'white' } }), (card.label === "Total Exams" || card.label === "Generated Reports") && _jsx(DescriptionIcon, { style: { fontSize: '50px', color: 'white' } }), card.label === "Unfinished Exams" && _jsx(RestorePageIcon, { style: { fontSize: '50px', color: 'white' } }), card.label === "Finished Exams" && _jsx(TaskIcon, { style: { fontSize: '50px', color: 'white' } }), card.label === "Advanced - Yearly" && _jsx(StarIcon, { style: { fontSize: '50px', color: 'white' } })] }), _jsxs(Grid, { item: true, style: { width: "50%", textAlign: 'right', flexGrow: 1 }, children: [_jsx(Typography, { variant: "h6", style: { fontWeight: "normal", color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,0.1)' }, lineHeight: "1em", children: card.title }), card.subtitle && _jsx(Typography, { variant: "body2", style: { opacity: 0.9, color: 'white' }, children: card.subtitle }), _jsx(Typography, { variant: "h4", style: { color: 'white', fontWeight: 'bold', marginTop: '10px', textShadow: '2px 2px 4px rgba(0,0,0,0.2)' }, children: card.value })] })] }) }) }, index)); }) }), _jsx(Grid, { container: true, spacing: 1, marginBottom: 3, children: cards2.map(function (card, index) { return (_jsx(Grid, { item: true, xs: 12, sm: isSmallAndHamburger2 ? 12 : 6, children: _jsxs(Card, { style: { width: '100%', height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: card.title, style: {
                                        background: card.headerColor,
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), (index === 0) && (_jsx(CardContent, { sx: {
                                        padding: '30px',
                                        height: {
                                            xs: '300px', // minimum height on mobile
                                            sm: winwidth / 3 // responsive height based on window width
                                        },
                                        maxHeight: '400px',
                                        minHeight: '300px',
                                        width: '100%',
                                        overflow: 'auto'
                                    }, children: _jsx(Box, { sx: {
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }, children: _jsx(BarChart, { xAxis: [{
                                                    scaleType: 'band',
                                                    data: ['0-20y', '20-40y', '40-60y', '60-80y', '>80y', 'N/A'],
                                                    tickLabelStyle: {
                                                        angle: {
                                                            xs: -45, // angled labels on mobile
                                                            sm: 0 // straight labels on larger screens
                                                        },
                                                        textAnchor: 'middle',
                                                    }
                                                }], series: [
                                                {
                                                    data: barData1.data,
                                                    label: translate('params.dashboard.TotalSubjects'),
                                                    valueFormatter: function (value) { return "".concat(value, " students"); }
                                                },
                                                {
                                                    data: barData2.data,
                                                    label: translate('params.dashboard.HighRiskSubjects'),
                                                    valueFormatter: function (value) { return "".concat(value, " students"); }
                                                }
                                            ], slotProps: {
                                                legend: {
                                                    position: {
                                                        vertical: 'top',
                                                        horizontal: 'middle'
                                                    },
                                                    padding: 8,
                                                }
                                            }, sx: {
                                                width: '100%',
                                                height: '100%',
                                                '& .MuiChartsLegend-root': {
                                                    flexWrap: 'wrap',
                                                }
                                            } }) }) })), (index === 1) && (_jsxs(Grid, { item: true, children: [_jsx(CardContent, { style: { height: winwidth / 3, maxHeight: '332px', minHeight: "230px", overflow: 'auto' }, children: _jsx(InfiniteList
                                            //pagination={<PostPagination />}
                                            , { 
                                                //pagination={<PostPagination />}
                                                resource: "dashboard/subject/highRisk/org", actions: false, perPage: 5, children: _jsxs(Datagrid, { bulkActionButtons: false, style: { tableLayout: "fixed", minWidth: '100%' }, sx: {
                                                        '& .RaDatagrid-row': rowStyle,
                                                        '& .RaDatagrid-headerCell': rowHeaderStyle,
                                                        '& .RaDatagrid-rowCell': rowCellStyle,
                                                    }, rowStyle: postRowStyle, children: [_jsx(FunctionField, { source: "subject_firstname", label: translate("pages.settings.fields.profile.info.name"), render: function (record) {
                                                                return (_jsx(Typography, { onClick: function () {
                                                                        var state = { customId: record === null || record === void 0 ? void 0 : record.subject_customId };
                                                                        navigate("/admin/subjects", { state: state });
                                                                    }, sx: { cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }, children: isTabScreen ?
                                                                        _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.settings.fields.profile.info.name"), ":"] }), _jsx(Typography, { className: "small-text", children: "".concat(record.subject_firstname, " ").concat(record.subject_lastname) })] })
                                                                        : "".concat(record.subject_firstname, " ").concat(record.subject_lastname) }));
                                                            } }), _jsx(FunctionField, { source: "eye", label: translate("pages.exam.fields.eye"), render: function (record) {
                                                                var translatedEye = record.eye ? translate("params.exams.eye.".concat(record.eye)) : "";
                                                                return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                                        _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.exam.fields.eye"), ":"] }), _jsx(Typography, { className: "small-text", children: translatedEye })] })
                                                                        : translatedEye }));
                                                            } }), _jsx(FunctionField, { source: "examDate", label: translate("pages.exam.fields.exam_date"), render: function (record) {
                                                                return (_jsx(Typography, { children: isTabScreen ?
                                                                        _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.exam.fields.exam_date"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record.examDate) })] })
                                                                        : dbDateHelper(record.examDate) }));
                                                            } }), _jsx(FunctionField, { source: "model", label: translate("pages.exam.fields.ai_model"), render: function (record) {
                                                                var translatedModel = record.model ? translate("pages.exam.fields.params.ai_model.".concat(record.model)) : "";
                                                                return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                                        _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.exam.fields.ai_model"), ":"] }), _jsx(Typography, { className: "small-text", children: translatedModel })] })
                                                                        : translatedModel }));
                                                            } })] }) }) }), _jsx(CardActions, { style: { justifyContent: 'center', padding: '16px' }, children: _jsx(Button, { variant: "contained", color: "primary", style: {
                                                    background: 'linear-gradient(135deg, #D32F2F 0%, #C62828 100%)',
                                                    color: 'white',
                                                    borderRadius: '8px',
                                                    padding: '6px 12px',
                                                    fontSize: '0.7rem',
                                                    fontWeight: 'bold',
                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                                    transition: 'all 0.3s ease-in-out'
                                                }, onClick: function () { return window.open('/admin/subjects', '_blank'); }, onMouseEnter: function (e) {
                                                    e.currentTarget.style.transform = 'scale(1.05)';
                                                    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)';
                                                }, onMouseLeave: function (e) {
                                                    e.currentTarget.style.transform = 'scale(1)';
                                                    e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)';
                                                }, children: translate('pages.subject.ViewSubject') }) })] }))] }) })); }) }), _jsx(Grid, { container: true, spacing: 1, children: cards3.map(function (card, index) { return (_jsx(Grid, { item: true, xs: 12, sm: isSmallAndHamburger ? 12 : 6, md: 4, children: _jsxs(Card, { style: { width: '100%', height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderBottom: '1px solid rgba(0, 0, 0, 0.3)' }, children: [_jsx(CardHeader, { title: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', width: '100%' }, children: [_jsx("span", { children: card.title1 }), _jsx("span", { children: translate('pages.dashboard.title.total') + pies[index].data.reduce(function (sum, item) { return sum + item.value; }, 0) })] }), style: {
                                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                        color: 'white',
                                        padding: '10px',
                                        borderTopLeftRadius: '20px',
                                        borderTopRightRadius: '20px',
                                        width: '100%',
                                        // height: "50%",
                                        // height:"10%",
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { style: { height: "300px", width: "100%", justifyContent: "center", alignContent: "center", display: "flex" }, children: pies[index].data.every(function (item) { return item.value === 0; }) ? (
                                    // <ResponsiveContainer>
                                    _jsx("div", { style: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '250px',
                                            height: '248px',
                                            // width : "100%",
                                            // height : width,
                                            borderRadius: '50%',
                                            backgroundColor: '#f0f0f0',
                                        }, children: _jsx(Typography, { variant: "h6", align: "center", fontWeight: 900, children: "No data" }) })
                                    // </ResponsiveContainer>
                                    ) : (
                                    //<ResponsiveContainer>
                                    _jsxs(PieChart, { width: 300, height: 250, children: [_jsx(Pie, { data: pies[index].data.map(function (item) { return (__assign(__assign({}, item), { label: item.label === null || item.label === 'NULL' || item.label === undefined ? 'N/A' : item.label })); }), dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", outerRadius: 80, fill: "#82ca9d", children: pies[index].data.map(function (entry, index) { return (_jsx(Cell, { fill: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] })
                                    //</ResponsiveContainer>
                                    ) })] }) })); }) })] }) }));
};
export default YourDashboard;
