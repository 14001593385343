var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { dbDateHelper, } from "../utils/helper";
import { DatagridConfigurable, ExportButton, List, SelectColumnsButton, TopToolbar, WrapperField, FunctionField, useDataProvider, useResourceContext, useNotify, useRefresh, useTranslate, Confirm, } from "react-admin";
import { useMediaQuery, Divider, Typography, Grid, IconButton, Button, } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { general } from "../utils/componentWizard";
import CreateDialog from "./CreateDialog";
import Tooltip from "@mui/material/Tooltip";
import FilterList from "./FilterList.tsx";
import { acl } from "../utils/dummyData";
import globalStyles from "../styles/globalStyles";
import { subjectIdHelper } from "../utils/helper";
import { TranslateUsersOrgClassHelper } from "../utils/TranslateClassHelper";
import EditComp from "./EditComp";
import { GenderMuiChoices } from "../subjectPage/Params";
import { BODY_GRID_PADDING, NORMAL_GRID_SPACE, } from "../styles/styleConstants.data";
import "../styles/global.module.css";
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var OrganizationList = function (props) {
    var location = useLocation();
    var navigate = useNavigate();
    var resource = useResourceContext(props);
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    // const [data, setData] = useState(dummyData); //data table data
    // const resource = "UserList";
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(acl), acessControlList = _b[0], setAcessControlList = _b[1];
    var myOrg = localStorage.getItem('appBarOrg') || '';
    var _c = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _c[0], setFilters = _c[1];
    var _d = useState({ gender: GenderMuiChoices }), params = _d[0], setParams = _d[1]; //data table data
    var _e = useState({}), selectedRow = _e[0], setSelectedRow = _e[1];
    var _f = useState({}), selectedViewOrg = _f[0], setSelectedViewOrg = _f[1]; //when onclick the "eye" btn on list
    // dialog for org
    var _g = useState(false), dialogOpened = _g[0], setDialogOpened = _g[1];
    var _h = useState({}), dialogData = _h[0], setDialogData = _h[1];
    // dialog for create
    var _j = useState(false), createDialogOpened = _j[0], setCreateDialogOpened = _j[1];
    var translateUsersClassHelper = new TranslateUsersOrgClassHelper(translate);
    var isMobileScreen = useMediaQuery("(max-width: 750px)");
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        //backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
        // ':hover': {
        //   backgroundColor: 'Black',
        // },
    }); };
    var closeDialog = function () {
        setDialogOpened(false);
    };
    var delAction = function (record) {
        var params = {
            data: {
                givenId: record.id,
            },
        };
        dataProvider.delete(resource, params).then(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        refresh();
                        return [4 /*yield*/, resetParams()];
                    case 1:
                        _a.sent();
                        notify(translate("pages.organizations.notify.delete"));
                        return [2 /*return*/];
                }
            });
        }); }).catch(function (error) {
            if (error == "HttpError2: This organization id does not belong to any organizations") {
                notify(translate("error.organization.noId"), {
                    type: 'error',
                });
            }
            else if (error == "HttpError2: Unauthorized") {
                notify(translate("error.ACL.unauthorized"), {
                    type: 'error',
                });
            }
            else {
                notify(translate("error.organization.delFailed"), {
                    type: 'error',
                });
            }
        });
    };
    var setDialogDataAction = function (record) {
        var _a;
        setDialogData(__assign({ submit: null, dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create", id: (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : null }, record));
    };
    var openEditDialog = function (record) {
        //open dialog
        setDialogDataAction(record);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    // create
    var openCreateDialog = function (record) {
        //open dialog
        setDialogDataAction(record);
        // formik?.current?.resetForm();
        setCreateDialogOpened(true);
    };
    var reloadRecord = function (values, dialogType) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedPassword, updatedConfirmpw, params_1;
        return __generator(this, function (_a) {
            setIsLoading(true);
            try {
                updatedPassword = !values.password || values.password === "" ? null : values.password;
                updatedConfirmpw = !values.confirmpw || values.confirmpw === "" ? null : values.confirmpw;
                if (updatedPassword != updatedConfirmpw) {
                    notify(translate('error.organization.dupEmailUsn'), {
                        type: 'error',
                    });
                    return [2 /*return*/];
                }
                params_1 = {
                    data: {
                        name: values.orgName,
                    },
                };
                switch (dialogType) {
                    case "Create":
                        dataProvider
                            .create(resource, params_1)
                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        notify(translate("pages.organizations.notify.create"));
                                        refresh();
                                        // window.location.reload();
                                        return [4 /*yield*/, resetParams()];
                                    case 1:
                                        // window.location.reload();
                                        _a.sent();
                                        setCreateDialogOpened(false);
                                        setIsLoading(false);
                                        return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            if (error == "HttpError2: Please enter valid organization name" ||
                                error == "HttpError2: This organization name is invalid!") {
                                notify(translate("error.organization.validOrg"), {
                                    type: 'error',
                                });
                            }
                            else if (error == "HttpError2: Unauthorized") {
                                notify(translate("error.ACL.unauthorized"), {
                                    type: 'error',
                                });
                            }
                            else if (error == "HttpError2: This organization name is alraedy being used, please consider use a different name!") {
                                notify(translate("error.organization.nameUsed"), {
                                    type: 'error',
                                });
                            }
                            else {
                                notify(translate("error.organization.createFailed"), {
                                    type: 'error',
                                });
                            }
                            setIsLoading(false);
                        });
                        break;
                    case "Edit":
                        params_1.data.id = values.id;
                        // params.data.givenId = values.id;
                        dataProvider
                            .updateOne(resource, params_1, values.id)
                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        notify(translate("pages.organizations.notify.update"));
                                        getACL();
                                        return [4 /*yield*/, resetParams()];
                                    case 1:
                                        _a.sent();
                                        // const timeoutId = setTimeout(() => {
                                        // }, 30);
                                        setIsLoading(false);
                                        refresh();
                                        setCreateDialogOpened(false);
                                        return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            refresh();
                            if (error == "HttpError2: This organization id does not belong to any organizations") {
                                notify(translate("error.organization.noId"), {
                                    type: 'error',
                                });
                            }
                            else if (error == "HttpError2: Unauthorized") {
                                notify(translate("error.ACL.unauthorized"), {
                                    type: 'error',
                                });
                            }
                            else if (error == "HttpError2: This organization name is alraedy being used, please consider use a different name!") {
                                notify(translate("error.organization.nameUsed"), {
                                    type: 'error',
                                });
                            }
                            else {
                                notify(translate('error.organization.updateFailed'), {
                                    type: 'error',
                                });
                            }
                            setIsLoading(false);
                        });
                        break;
                }
                setDialogData(__assign({}, values));
            }
            catch (err) {
                console.log(err, "==err");
                refresh();
                notify(translate('error.organization.updateFailed'), {
                    type: 'error',
                });
                setIsLoading(false);
                // dispatch(actionTypes.SNACKBAR_ERROR);
            }
            return [2 /*return*/];
        });
    }); };
    // get ACL for org (top bar)
    var getACL = function () {
        var _a;
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        var settingsParams = {};
        if (cachedAuth) {
            settingsParams.id = cachedAuth.id;
            dataProvider
                .getOneWithoutDataJSON("users", settingsParams)
                .then(function (rtnData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                // setAcessControlList()
                setAcessControlList((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.ACL);
                localStorage.setItem("appBarOrg", (_e = (_d = (_c = (_b = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c['current_organization']) === null || _d === void 0 ? void 0 : _d[1]) !== null && _e !== void 0 ? _e : "N/A");
                localStorage.setItem("userOrgId", (_j = (_h = (_g = (_f = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _f === void 0 ? void 0 : _f.info) === null || _g === void 0 ? void 0 : _g['current_organization']) === null || _h === void 0 ? void 0 : _h[0]) !== null && _j !== void 0 ? _j : "N/A");
                refresh();
            })
                .catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
            });
        }
    };
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var sessonStorageJSON, cachedAuth, settingsParams;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
                    if (sessonStorageJSON) {
                        setFilters(__assign(__assign({}, filters), sessonStorageJSON));
                    }
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, filters), sessonStorageJSON)));
                    searchFilterGroup();
                    cachedAuth = (_b = JSON.parse(localStorage.getItem("auth"))) !== null && _b !== void 0 ? _b : {};
                    settingsParams = {};
                    if (cachedAuth) {
                        settingsParams.id = cachedAuth.id;
                        dataProvider
                            .getOneWithoutDataJSON("users", settingsParams)
                            .then(function (rtnData) {
                            var _a;
                            /* const newACList = rtnData?.profile?.ACL || {};
                            setAcessControlList(newACList); */
                            var newACList = ((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.ACL) || {};
                            setAcessControlList(newACList);
                        })
                            .catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            localStorage.setItem("appBarOrg", "N/A");
                            localStorage.setItem("userOrgId", "N/A");
                            notify(translate('error.ACL.getACLFailed'), {
                                type: 'error',
                            });
                        });
                    }
                    return [4 /*yield*/, resetParams()];
                case 1:
                    _c.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var resetParams = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newParams, orgParams, orgData, userGroupData;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    newParams = __assign(__assign({}, params), translateUsersClassHelper.translateAllParams(translate));
                    orgParams = {
                        pagination: { page: 1, perPage: 200 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: {}
                    };
                    return [4 /*yield*/, dataProvider.getListNoFilter("organizations", orgParams)];
                case 1:
                    orgData = (_c.sent());
                    orgData = (_a = orgData === null || orgData === void 0 ? void 0 : orgData.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({ id: item.id, name: item.orgName, value: item.id }); });
                    newParams["organizations"] = orgData;
                    setParams(newParams);
                    return [4 /*yield*/, dataProvider.getListNoFilter("userGroup", orgParams)];
                case 2:
                    userGroupData = _c.sent();
                    userGroupData = (_b = userGroupData === null || userGroupData === void 0 ? void 0 : userGroupData.data) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({
                        id: item.id,
                        name: item.name,
                        value: item.id,
                        orgId: item.orgId,
                    }); });
                    newParams["userGroups"] = userGroupData;
                    refresh();
                    setParams(newParams);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var newParams = translateUsersClassHelper.translateAllParams(translate);
        setParams(__assign(__assign({}, params), { gender: newParams.gender }));
    }, [translate]);
    useEffect(function () {
        refresh();
    }, [filters]);
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        // update the filter labels
        // let updatedFilters = JSON.parse(JSON.stringify(filters));
        // if (updatedFilters?.educationLevel) {
        //     updatedFilters.educationLevel = EducationLevelMuiChoices[filters.educationLevel].label
        // }
        // if (updatedFilters?.gender) {
        //     updatedFilters.gender = GenderMuiChoices[filters.gender].label
        // }
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "id", order: "ASC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: __assign(__assign({}, filters), sessonStorageJSON),
        };
        dataProvider
            .getList(resource, params)
            .then(function (data) {
            refresh();
            notify(translate('pages.organizations.notify.get'));
            setIsLoading(false);
        })
            .catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            setIsLoading(false);
        });
    };
    var _k = useState(false), confirmOpen = _k[0], setConfirmOpen = _k[1];
    var _l = useState(null), recordToDelete = _l[0], setRecordToDelete = _l[1];
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleConfirm = function () {
        if (recordToDelete) {
            delAction(recordToDelete);
            getACL();
        }
        setConfirmOpen(false);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    return (_jsxs(Grid, { container: true, padding: BODY_GRID_PADDING, columnGap: NORMAL_GRID_SPACE, rowGap: 2, direction: { xs: 'row', lg: 'column' }, children: [!dialogOpened && (_jsxs(Grid, { container: true, xs: 12, lg: 12, direction: 'row', rowGap: 1, children: [_jsx(Grid, { xs: 12, children: _jsx(general.breadcrumb, { trail: [translate("breadcrumb.userManagement"), "".concat(translate("pages.organizations.name"))] }) }), _jsxs(Grid, { container: true, display: "flex", alignItems: "center", justifyContent: "start", direction: 'row', columnGap: NORMAL_GRID_SPACE, children: [_jsx(Grid, { children: _jsx(Typography, { className: 'normal-text large-bold', children: translate("pages.organizations.name") }) }), acessControlList.organization_admin.create && (_jsx(Grid, { children: _jsx(Button, { variant: "contained", startIcon: _jsx(AddCircleOutlineIcon, {}), onClick: openCreateDialog, children: translate("pages.organizations.addNew") }) }))] }), _jsx(Grid, { xs: 12, children: _jsx(Divider, {}) }), _jsx(Grid, { xs: 12, children: _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }) }), _jsx(Grid, { xs: 12, children: _jsx(List, { filterDefaultValues: filters, sort: { field: "id", order: "DESC" }, empty: false, actions: _jsx(ListActions, {}), sx: { width: "100%" }, children: _jsxs(DatagridConfigurable, { bulkActionButtons: false, style: { tableLayout: "fixed" }, rowStyle: postRowStyle, sx: {
                                    '& .RaDatagrid-row': rowStyle,
                                    '& .RaDatagrid-headerCell': rowHeaderStyle,
                                    '& .RaDatagrid-rowCell': rowCellStyle,
                                }, children: [_jsx(FunctionField, { source: "id", label: translate("pages.organizations.fields.id"), render: function (record) { return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.id"), ":"] }), _jsx(Typography, { className: "small-text", children: subjectIdHelper(record.id) })] })
                                                : subjectIdHelper(record.id) })); } }), _jsx(FunctionField, { source: "orgName", label: translate("pages.organizations.fields.orgName"), sortable: false, render: function (record) {
                                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.orgName"), ":"] }), _jsx(Typography, { className: "small-text", children: record.orgName })] })
                                                    : record.orgName }));
                                        } }), _jsx(FunctionField, { source: "createdAt", label: translate("pages.organizations.fields.createdAt"), render: function (record) {
                                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.createdAt"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record.createdAt) })] })
                                                    : dbDateHelper(record.createdAt) }));
                                        } }), _jsx(FunctionField, { source: "updatedAt", label: translate("pages.organizations.fields.updatedAt"), render: function (record) {
                                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.updatedAt"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record.updatedAt) })] })
                                                    : dbDateHelper(record.updatedAt) }));
                                        } }), _jsx(FunctionField, { source: "groupNum", label: translate("pages.organizations.fields.groupNum"), render: function (record) {
                                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.groupNum"), ":"] }), _jsx(Typography, { className: "small-text", children: record.groupNum })] })
                                                    : record.groupNum }));
                                        } }), _jsx(FunctionField, { source: "userNum", label: translate("pages.organizations.fields.userNum"), render: function (record) {
                                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.organizations.fields.userNum"), ":"] }), _jsx(Typography, { className: "small-text", children: record.userNum })] })
                                                    : record.userNum }));
                                        } }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(FunctionField, { source: "", label: "", render: function (record) { return (_jsx(Grid, { item: true, onClick: function () {
                                                            setSelectedViewOrg(record);
                                                            openEditDialog(record);
                                                        }, children: _jsx(Tooltip, { title: (!acessControlList.organization_admin.update && (!acessControlList.organization_normal.update || record.orgName !== (localStorage.getItem('appBarOrg')))) ? translate("tooltip.viewOrg") : translate("tooltip.viewAndEditOrg"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(PreviewIcon, { fontSize: "small" }) }) }) })); } }), (_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                                        var isMyOrg = record.orgName === myOrg;
                                                        var canDelete = (acessControlList.organization_admin.delete ||
                                                            (acessControlList.organization_normal.delete && isMyOrg));
                                                        if (canDelete) {
                                                            return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.deleteOrg"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () { handleDelete(record); }, children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                                        }
                                                        return null;
                                                    } }))] }) })] }) }) })] })), createDialogOpened && (_jsx(CreateDialog, { opened: createDialogOpened, data: dialogData, params: params, translate: translate, closeDialog: function () {
                    setCreateDialogOpened(false);
                }, reloadRecord: reloadRecord, isMobileScreen: isMobileScreen })), dialogOpened && (_jsxs(Grid, { item: true, md: 12, xs: 12, sx: { width: "100%" }, children: [_jsx(general.breadcrumb, { trail: [
                            translate("breadcrumb.userManagement"),
                            "".concat(translate("pages.organizations.name")),
                            selectedViewOrg.orgName ? "#".concat(selectedViewOrg.orgName) : "",
                        ] }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", sx: { width: "100%" }, children: [_jsx(Typography, { variant: "h5", sx: { paddingRight: "10px" }, children: translate("pages.organizations.name") }), _jsx(Typography, { variant: "h5", sx: { paddingRight: "10px" }, children: selectedViewOrg.orgName })] }), _jsx(EditComp, { getACL: getACL, resource: resource, resetParams: resetParams, data: dialogData, closeDialog: closeDialog, opened: dialogOpened, params: params, acessControlList: acessControlList, reloadRecord: reloadRecord, setDialogData: setDialogData, selectedViewOrg: selectedViewOrg, selectedViewOrgName: selectedViewOrg.orgName, isMobileScreen: isMobileScreen })] })), _jsx(Confirm, { isOpen: confirmOpen, title: translate("tooltip.deleteOrg"), content: translate("comfirmDialog.organization"), onConfirm: handleConfirm, onClose: handleCancel })] }));
};
export default OrganizationList;
