var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSidebarState } from 'react-admin';
import { useMediaQuery, Typography, Grid, IconButton, Button, Box, Card, CardContent } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, setClickedPanelComp = _a.setClickedPanelComp, backgroundColor = _a.backgroundColor;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: {
            cursor: "pointer",
            backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white"
        }, onClick: function () { }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var SubscriptionBar = function (_a) {
    var translate = _a.translate, gens = _a.gens;
    var open = useSidebarState()[0];
    var isSmallAndHamburger = useMediaQuery("(max-width: 750px)") && open;
    var over600 = useMediaQuery("(min-width: 600px)");
    var ha = 'flex';
    if (!over600 || isSmallAndHamburger) {
        ha = 'grid';
    }
    return (_jsx(Box, { sx: {
            backgroundColor: '#e8f4fd',
            padding: 2,
            marginY: 3,
            borderRadius: 2,
            display: ha,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: "100%"
        }, children: _jsx(Box, { children: _jsxs(Typography, { variant: "body2", color: "secondary", children: [gens, translate("pages.membership.fields.plans.genLeft")] }) }) }));
};
var SubscriptionCard = function (_a) {
    var translate = _a.translate, el = _a.el, idx = _a.idx, gens = _a.gens, handleSubscribe = _a.handleSubscribe, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, deleteProduct = _a.deleteProduct, openEditDialog = _a.openEditDialog, reloadRecord = _a.reloadRecord, closeDialog = _a.closeDialog, acl = _a.acl;
    var isFour = useMediaQuery("(min-width: 1200px)");
    var open = useSidebarState()[0];
    var mR = 0.2;
    if (idx == 3 || (idx == 1 && !isFour)) {
        mR = 0;
    }
    var isSmallAndHamburger = useMediaQuery("(max-width: 750px)") && open;
    return (_jsx(Grid, { item: true, lg: 3.8, xs: 12, marginX: 0, children: _jsxs(Card, { variant: "outlined", sx: {
                borderRadius: '20px',
                textAlign: 'center',
                padding: 3,
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }, children: [acl.package_admin.delete && (_jsx(IconButton, { "aria-label": "delete", size: "small", sx: {
                        position: 'absolute',
                        top: 1,
                        right: 8,
                        color: 'text.secondary',
                    }, onClick: function () { deleteProduct(el === null || el === void 0 ? void 0 : el.key); }, children: _jsx(RemoveIcon, { fontSize: "small" }) })), _jsxs(CardContent, { sx: { padding: 0 }, children: [_jsx(Typography, { variant: "h6", sx: {
                                fontWeight: 'bold',
                                color: '#0066cc',
                                marginBottom: "1em"
                            }, children: el.name }), _jsx(Box, { sx: { textAlign: 'left', marginBottom: 4 }, children: _jsxs("ul", { style: {
                                    listStyleType: 'disc',
                                    paddingLeft: '1.5em',
                                }, children: [_jsx("li", { children: _jsxs(Typography, { variant: "body1", sx: { fontSize: '1rem', marginBottom: 2 }, children: [_jsx("strong", { children: (el === null || el === void 0 ? void 0 : el.quota) + " " }), translate("pages.membership.fields.details.genMonthly")] }) }), _jsx("li", { children: _jsx(Typography, { variant: "body1", sx: { fontSize: '1rem' }, children: el.description }) })] }) }), _jsx(Box, { sx: { marginY: 4 }, children: _jsxs(Typography, { variant: "h6", sx: { fontWeight: 'bold' }, children: ["USD ", el.price] }) }), _jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: 1 }, children: _jsx(Button, { disabled: isLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, handleSubscribe(el === null || el === void 0 ? void 0 : el.key)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                }); }); }, variant: "contained", sx: {
                                    backgroundColor: '#0066cc',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '10px 20px',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        backgroundColor: '#0052a3'
                                    }
                                }, children: translate("pages.membership.btn.purchase") }) }), _jsxs(Box, { sx: { marginTop: 3 }, children: [_jsx(Typography, { variant: "caption", color: "textSecondary", children: translate("pages.membership.fields.details.subsPreTermsAndCond") }), _jsx(Typography, { variant: "caption", sx: {
                                        color: '#0066cc',
                                        textDecoration: 'underline',
                                        marginLeft: '4px',
                                        cursor: 'pointer'
                                    }, children: translate("pages.membership.fields.details.termsAndCond") }), acl.package_admin.update && (_jsx(Box, { sx: { marginTop: 1 }, children: _jsx(Button, { variant: "contained", size: "small", sx: {
                                            backgroundColor: '#0066cc',
                                            color: 'white',
                                            padding: '2px 8px',
                                            borderRadius: '8px',
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                backgroundColor: '#0052a3'
                                            }
                                        }, onClick: function () { openEditDialog(el); }, children: translate("dialog.Edit") }) }))] })] })] }) }));
};
export { PanelComp, SubscriptionBar, SubscriptionCard };
