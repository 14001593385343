var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh, useTranslate, useSidebarState, useSetLocale, } from 'react-admin';
import { useMediaQuery, Typography, Grid, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { general } from '../utils/componentWizard';
import { settingsStyles as styles } from './helper/styles';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ProfileComp from './components/Profile';
import LanguageComp from './components/Language';
import SecurityComp from './components/Security';
import PackageComp from './components/Package';
import EditDialog from './helper/EditDialog';
import { GenderMuiChoices } from '../subjectPage/Params';
import { LanguageChoices } from './Params';
import { acl } from "../utils/dummyData";
import { BODY_GRID_PADDING, } from "../styles/styleConstants.data";
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, handleOnclick = _a.handleOnclick, backgroundColor = _a.backgroundColor, name = _a.name;
    return (_jsxs(Grid, { item: true, display: "flex", alignItems: "center", padding: 0.5, style: { cursor: "pointer", backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white" }, onClick: function () { handleOnclick(name); }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", noWrap: true, children: text })] }));
};
var Settings = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var setLocale = useSetLocale();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var isMobileScreen = useMediaQuery("(max-width: 650px)");
    var _a = useState({
        gender: GenderMuiChoices,
        language: LanguageChoices
    }), params = _a[0], setParams = _a[1];
    //<package> component 
    var _b = useState([]), subCard = _b[0], setSubCard = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    // const resource = "dataexport";
    var panelCompNames = [{ name: "profile", label: translate("pages.settings.leftCol.profile"), icon: _jsx(AccountCircleIcon, {}) },
        { name: "language", label: translate("pages.settings.leftCol.language"), icon: _jsx(LanguageIcon, {}) },
        { name: "security", label: translate("pages.settings.leftCol.security"), icon: _jsx(SecurityIcon, {}) },
        { name: "package", label: translate("pages.settings.leftCol.package"), icon: _jsx(CardMembershipIcon, {}) },
        // { name: "payment", label: translate(`pages.settings.leftCol.payment`), icon: <CardMembershipIcon /> },
    ];
    var _d = useState(acl), accessDialogData = _d[0], setAccessDialogData = _d[1];
    var _e = useState(true), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState("profile"), curSelectedComp = _f[0], setCurSelectedComp = _f[1]; //profile, language, security
    var _g = useState({}), originalInfo = _g[0], setOriginalInfo = _g[1];
    var _h = useState({
        profile: {
            info: {
                userId: '',
                userGroup: '',
                username: '',
                lastName: '',
                firstName: '',
                email: '',
                gender: '',
            },
            pw: {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            },
            access: {
                Dashboard: {
                    add: false, view: true, edit: false, delete: false
                },
                User: {
                    add: true, view: true, edit: true, delete: true
                },
                UserGroup: {
                    add: false, view: false, edit: false, delete: false
                },
                Subject: {
                    add: true, view: true, edit: true, delete: true
                },
                DataExport: {
                    add: false, view: true, edit: false, delete: false
                }
            }
        },
        language: "English",
        security: false,
        gens: 0,
    }), filters = _h[0], setFilters = _h[1];
    var open = useSidebarState();
    var isMediumDevice = useMediaQuery(
    // "(max-width : 992px)"
    "(max-width : 768px)");
    var isMediumDevice2 = useMediaQuery(
    // "(max-width : 992px)"
    "(max-width : 800px)") && open;
    var settingsstyle = isMediumDevice2 || isMediumDevice ? Object.assign({}, styles.componentContainer) : Object.assign({}, styles.componentContainer, styles.settingsWidth);
    useEffect(function () {
        var _a;
        var params = {};
        var cachedAuth = (_a = JSON.parse(localStorage.getItem('auth'))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth) {
            params.id = cachedAuth.id; //user id
            setFilters({});
            dataProvider.getOneWithoutDataJSON("users", params)
                .then(function (data) {
                var _a, _b, _c, _d, _e, _f, _g;
                var newFilters = __assign(__assign({}, filters), data);
                newFilters['profile']['info']['userId'] = cachedAuth.id;
                // newFilters['profile']['info']['username'] = cachedAuth.username;
                // newFilters['profile']['info']['email'] = cachedAuth.email;
                newFilters['profile']['pw'] = {
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                };
                setOriginalInfo(__assign({}, data.profile.info));
                newFilters['profile']['access'] = __assign({}, (_a = data === null || data === void 0 ? void 0 : data.profile) === null || _a === void 0 ? void 0 : _a.access);
                //update usergroup filter
                var newUserGroups = (_c = (_b = newFilters['profile']) === null || _b === void 0 ? void 0 : _b['info']) === null || _c === void 0 ? void 0 : _c['userGroups'].map(function (item) { return item[1]; }).join(', ');
                newFilters['profile']['info']['editUserGroups'] = newUserGroups;
                // Add dataProvider.getProducts() call
                /*   dataProvider.GetProducts()
                      .then((productsData) => {
                          setSubCard(productsData)
                          // Optionally, you can update newFilters with products data if needed
                      })
                      .catch((error) => {
                          console.log(error, '==error fetching products');
                      }); */
                // set default language is eng
                if (!newFilters.language) {
                    newFilters['language'] = 'en';
                }
                setFilters(newFilters);
                setAccessDialogData(__assign(__assign({}, accessDialogData), (_d = data === null || data === void 0 ? void 0 : data.profile) === null || _d === void 0 ? void 0 : _d.ACL));
                refresh();
                localStorage.setItem("appBarOrg", (_g = (_f = (_e = data === null || data === void 0 ? void 0 : data.profile) === null || _e === void 0 ? void 0 : _e.info) === null || _f === void 0 ? void 0 : _f['current_organization'][1]) !== null && _g !== void 0 ? _g : 'N/A');
                setIsLoading(false);
            }).catch(function (error) {
                // Handle any errors that occurred
                console.log(error, '==error');
                localStorage.setItem("appBarOrg", "N/A");
                localStorage.setItem("userOrgId", "N/A");
                notify(translate('error.ACL.getACLFailed'), {
                    type: 'error',
                });
                setIsLoading(false);
                setAccessDialogData(__assign({}, accessDialogData));
            });
        }
    }, []);
    var handleSave = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var params = {
            data: {}
        };
        // if no password related or is other components, save directly
        if ((!((_a = filters.profile.pw) === null || _a === void 0 ? void 0 : _a.currentPassword) && !((_b = filters.profile.pw) === null || _b === void 0 ? void 0 : _b.newPassword) && !((_c = filters.profile.pw) === null || _c === void 0 ? void 0 : _c.confirmNewPassword)) ||
            curSelectedComp != "profile") {
            switch (curSelectedComp) {
                case "profile":
                    // check if original info is not same as filters
                    params.data = {
                        First_name: filters.profile.info.firstName,
                        gender: filters.profile.info.gender, Last_name: filters.profile.info.lastName,
                    };
                    if (originalInfo.email != filters.profile.info.email) {
                        params.data.email = filters.profile.info.email;
                    }
                    if (originalInfo.username != filters.profile.info.username) {
                        params.data.username = filters.profile.info.username;
                    }
                    break;
                case "language":
                    params.data['language'] = filters.language;
                    if (filters.language === 'English') {
                        setLocale('en');
                        var cachedAuth = (_d = JSON.parse(localStorage.getItem("auth"))) !== null && _d !== void 0 ? _d : {};
                        if (cachedAuth) {
                            cachedAuth.language = 'en';
                            localStorage.setItem("auth", JSON.stringify(cachedAuth));
                        }
                    }
                    else {
                        setLocale('zh-HK');
                        var cachedAuth = (_e = JSON.parse(localStorage.getItem("auth"))) !== null && _e !== void 0 ? _e : {};
                        if (cachedAuth) {
                            cachedAuth.language = 'zh-HK';
                            localStorage.setItem("auth", JSON.stringify(cachedAuth));
                        }
                    }
                    break;
                case "security":
                    params.data['twofa'] = (_f = filters.security) !== null && _f !== void 0 ? _f : false;
                    break;
            }
            dataProvider.updateOne("users", params, filters.profile.info.userId)
                .then(function (data) {
                notify(translate('pages.settings.notify.updated'));
                var updatedAuth = JSON.parse(localStorage.getItem('auth'));
                updatedAuth.username = data.data.profile.info.username;
                localStorage.setItem('auth', JSON.stringify(updatedAuth));
                refresh();
                setIsLoading(false);
            }).catch(function (error) {
                // Handle any errors that occurred
                console.log(error, '==error');
                if (error == "HttpError2: This username or email is already in use.") {
                    notify(translate('error.settings.dupEmailUsn'), {
                        type: 'error',
                    });
                }
                else {
                    notify(translate('error.common.tryAgain'), {
                        type: 'error',
                    });
                }
                setIsLoading(false);
            });
        }
        // if have password, make some checkings
        else {
            switch (true) {
                // if current password and no new password
                case (filters.profile.pw.currentPassword && (!((_g = filters.profile.pw.newPassword) === null || _g === void 0 ? void 0 : _g.length) > 0 || !((_h = filters.profile.pw.confirmNewPassword) === null || _h === void 0 ? void 0 : _h.length) > 0)):
                    notify(translate('pages.settings.notify.newPW'));
                    break;
                // if no current password
                case (!filters.profile.pw.currentPassword && (((_j = filters.profile.pw.newPassword) === null || _j === void 0 ? void 0 : _j.length) > 0 || ((_k = filters.profile.pw.confirmNewPassword) === null || _k === void 0 ? void 0 : _k.length) > 0)):
                    notify(translate('pages.settings.notify.curPW'));
                    break;
                // else if have current password but new password not same as confirm new password
                case (((_l = filters.profile.pw.currentPassword) === null || _l === void 0 ? void 0 : _l.length) > 0 && (filters.profile.pw.newPassword != filters.profile.pw.confirmNewPassword)):
                    notify(translate('pages.settings.notify.newPWNotSame'), {
                        type: 'warning',
                    });
                    break;
                // update
                default:
                    // update both user info and password, 2 api
                    var cachedAuth = (_m = JSON.parse(localStorage.getItem('auth'))) !== null && _m !== void 0 ? _m : {};
                    // let cachedToken = JSON.parse(localStorage.getItem('accessToken')) ?? {}
                    var cachedToken = localStorage.getItem('accessToken');
                    var pwParams = {
                        data: __assign(__assign({}, filters.profile.pw), { token: cachedToken })
                    };
                    // check if original info is not same as filters
                    params.data = {
                        First_name: filters.profile.info.firstName,
                        gender: filters.profile.info.gender, Last_name: filters.profile.info.lastName,
                    };
                    if (originalInfo.email != filters.profile.info.email) {
                        params.data.email = filters.profile.info.email;
                    }
                    if (originalInfo.username != filters.profile.info.username) {
                        params.data.username = filters.profile.info.username;
                    }
                    //change pw api, then update settings api
                    dataProvider.changePW(pwParams)
                        .then(function (data) {
                        return dataProvider.updateOne("users", params, filters.profile.info.userId);
                    }).then(function () {
                        refresh();
                        setIsLoading(false);
                        notify(translate('pages.settings.notify.updated'));
                    }).catch(function (error) {
                        // Handle any errors that occurred
                        if (error == "HttpError2: Error changing password! Error message: Current password is incorrect" || error == "HttpError2: Current password is incorrect") {
                            notify(translate('error.settings.curPW'), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: Invalid password type" ||
                            error.message.includes("Invalid password type")) {
                            notify(translate('error.login.invalidPWType'), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: New passwords do not match") {
                            notify(translate('error.settings.newPW'), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: Error changing password! Error message: Current password is incorrect") {
                            notify(translate('error.settings.curPW'), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: User not found") {
                            notify(translate('error.settings.noUser'), {
                                type: 'error',
                            });
                        }
                        else {
                            notify(translate('error.settings.PW'), {
                                type: 'error',
                            });
                        }
                        setIsLoading(false);
                    });
            }
        }
    };
    // handle subscribe in "membership" component
    var handleSubscribe = function (product) { return __awaiter(void 0, void 0, void 0, function () {
        var subsParams, rtnData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    subsParams = { data: { product: product } };
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dataProvider.createOne("payment/buyQuota", subsParams)];
                case 2:
                    rtnData = _a.sent();
                    notify(translate('pages.settings.notify.updated'));
                    window.open(rtnData.data.data, '_blank');
                    refresh();
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    // Handle any errors that occurred
                    notify(translate('error.common.tryAgain'), {
                        type: 'error',
                    });
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _j = useState({}), dialogData = _j[0], setDialogData = _j[1];
    var _k = useState(false), dialogOpened = _k[0], setDialogOpened = _k[1];
    // create
    var openCreateDialog = function () {
        var obj = { dialogType: "Create" };
        setDialogData(obj);
        setDialogOpened(true);
    };
    var openEditDialog = function (el) {
        setDialogData({ dialogType: "Edit", data: el });
        setDialogOpened(true);
    };
    var closeDialog = function () {
        setDialogOpened(false);
    };
    var reloadRecord = function (values, data) { return __awaiter(void 0, void 0, void 0, function () {
        var Params, _a, returnData, editReturnData, error_2;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsLoading(true);
                    Params = {
                        data: {
                            name: values.name,
                            description: values.description,
                            quota: parseInt(values.quota),
                            price: parseInt(values.price),
                        },
                        key: (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.key
                    };
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 11, 12, 13]);
                    _a = data.dialogType;
                    switch (_a) {
                        case "Create": return [3 /*break*/, 2];
                        case "Edit": return [3 /*break*/, 6];
                    }
                    return [3 /*break*/, 10];
                case 2: return [4 /*yield*/, dataProvider.CreatePackage("payment/products", Params)];
                case 3:
                    returnData = _c.sent();
                    if (!returnData) return [3 /*break*/, 5];
                    refresh();
                    return [4 /*yield*/, fetchPackageData()];
                case 4:
                    _c.sent();
                    notify("Package added", { type: 'success' });
                    closeDialog();
                    _c.label = 5;
                case 5: return [3 /*break*/, 10];
                case 6: return [4 /*yield*/, dataProvider.EditPackage("payment/products", Params)];
                case 7:
                    editReturnData = _c.sent();
                    if (!editReturnData) return [3 /*break*/, 9];
                    refresh();
                    return [4 /*yield*/, fetchPackageData()];
                case 8:
                    _c.sent();
                    notify("Package updated", { type: 'success' });
                    closeDialog();
                    _c.label = 9;
                case 9: return [3 /*break*/, 10];
                case 10: return [3 /*break*/, 13];
                case 11:
                    error_2 = _c.sent();
                    console.error("Error:", error_2);
                    notify("Failed to add or update package", {
                        type: 'error',
                    });
                    return [3 /*break*/, 13];
                case 12:
                    setIsLoading(false);
                    refresh();
                    return [7 /*endfinally*/];
                case 13: return [2 /*return*/];
            }
        });
    }); };
    // <pacckage> comopnent fetch data
    var fetchPackageData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, translatedSubCard, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, dataProvider.GetProducts()];
                case 1:
                    data_1 = _a.sent();
                    console.log("Fetched products data:", data_1);
                    translatedSubCard = __spreadArray([], data_1.data, true);
                    translatedSubCard.map(function (el, idx) {
                        // set the default package translated name
                        if (el.name == 'Advanced package' || el.name == 'Standard package' || el.name == 'Basic package') {
                            el.name = translate("params.settings.package.".concat(el.name));
                        }
                    });
                    setSubCard(translatedSubCard);
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    console.error("Error fetching products:", error_3);
                    notify("error", { type: 'error' });
                    return [2 /*return*/, Promise.reject(error_3)];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Grid, { container: true, display: "flex", flexWrap: "wrap", style: styles.settingsContainer, padding: BODY_GRID_PADDING, children: _jsxs(Grid, { item: true, xs: 12, children: [_jsx(general.breadcrumb, { trail: ["".concat(translate("pages.settings.name"))] }), _jsx(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "start", children: _jsx(Typography, { variant: "h5", sx: { paddingRight: '10px', fontWeight: 'bold' }, children: translate("pages.settings.name") }) }), _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", children: [_jsx(Grid, { item /*lg={1.5} md={2} xs={12}*/: true, style: settingsstyle, marginBottom: 2, children: panelCompNames.map(function (el, idx) {
                                var backgroundColor;
                                if (el.name === curSelectedComp) {
                                    backgroundColor = "#F2F2F2";
                                }
                                return (_jsx(PanelComp, { icon: el.icon, text: el.label, name: el.name, backgroundColor: backgroundColor, handleOnclick: setCurSelectedComp }));
                            }) }), _jsxs(Grid, { item /*lg={10} md={9} xs={12}*/: true, xs: true, style: styles.componentContainer, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", marginBottom: "-0.5em", children: [_jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h5", sx: { marginBottom: '0.8em' }, children: translate("pages.settings.leftCol.".concat(curSelectedComp)) }) }), curSelectedComp !== "package" &&
                                            _jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate('common.save'), children: _jsx(SaveIcon, { sx: {
                                                            marginRight: '5px',
                                                            marginTop: '-1em', // Moved to top a little bit
                                                            transition: 'transform 0.3s ease',
                                                            '&:hover': {
                                                                transform: 'rotate(20deg)',
                                                            },
                                                        }, onClick: handleSave }) }) }), curSelectedComp === "package" && accessDialogData.package_admin.create && _jsx(Grid, { children: _jsx(Button, { variant: "contained", startIcon: _jsx(AddCircleOutlineIcon, {}), onClick: openCreateDialog, children: translate("pages.payment.fields.addNewPackage") }) })] }), curSelectedComp === "profile" && _jsx(ProfileComp, { params: params, translate: translate, filters: filters, setFilters: setFilters, accessDialogData: accessDialogData }), curSelectedComp === "language" && _jsx(LanguageComp, { params: params, translate: translate, filters: filters, setFilters: setFilters }), curSelectedComp === "security" && _jsx(SecurityComp, { params: params, translate: translate, filters: filters, setFilters: setFilters }), curSelectedComp === "package" && _jsx(PackageComp, { openEditDialog: openEditDialog, params: params, translate: translate, filters: filters, setFilters: setFilters, handleSubscribe: handleSubscribe, isLoading: isLoading, setIsLoading: setIsLoading, reloadRecord: reloadRecord, closeDialog: closeDialog, acl: accessDialogData, subCard: subCard, setSubCard: setSubCard, fetchPackageData: fetchPackageData }), dialogOpened && (_jsx(EditDialog, { reloadRecord: reloadRecord, opened: dialogOpened, data: dialogData, closeDialog: closeDialog, translate: translate, isMobileScreen: isMobileScreen }))] })] })] }) }));
};
export default Settings;
