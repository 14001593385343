var styles = {
    centerContainer: {
        display: "flex",
        flexWrap: "wrap",
        //justifyContent: "start",
        alignItems: "end",
        // backgroundColor: "Red"
    },
    tabSizeAddedTitle: {
        fontWeight: 410,
        paddingRight: "0.5rem",
        //width :'150px'
    },
};
export default styles;
