var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography, } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslate, useLogin, useNotify, useDataProvider, useRefresh, } from 'react-admin';
var RegisterSuccess = function (_a) {
    // const [loading, setLoading] = useState(false);
    var loading = _a.loading, signInInputs = _a.signInInputs, setSignInInputs = _a.setSignInInputs, setCurComp = _a.setCurComp, isMobileScreen = _a.isMobileScreen, isTabScreen = _a.isTabScreen;
    // sign up info
    // const [signUpInputs, setSignUpInputs] = useState({ email: "", password: "", confirmPassword: "" });
    var translate = useTranslate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var login = useLogin();
    var refresh = useRefresh();
    var location = useLocation();
    var navigate = useNavigate();
    // document.body.style.zoom = "100%";
    useEffect(function () {
    }, []);
    //register
    var handleSignUp = function (auth) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/];
        });
    }); };
    var cardWidth = "40%";
    switch (true) {
        case isMobileScreen:
            cardWidth = "75%";
            break;
        case isTabScreen:
            cardWidth = "60%";
            break;
    }
    return (_jsx(Grid, { item: true, width: "100%", height: "96vh", backgroundColor: "", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", children: _jsx(Card, { sx: {
                width: cardWidth,
                maxWidth: "27rem",
                borderRadius: 2, boxShadow: 3
            }, children: _jsxs(CardContent, { sx: { textAlign: 'center', padding: 4 }, children: [_jsx(CheckCircleIcon, { sx: { fontSize: 60, color: "#1976d2", marginBottom: 2 } }), _jsx(Typography, { variant: "h5", component: "div", children: "Sign Up" }), _jsx(Typography, { variant: "body2", color: "text.secondary", sx: { marginBottom: 2 }, children: "You have successfully activated your account." }), _jsx(Button, { variant: "contained", sx: { textTransform: 'none' }, onClick: function () {
                            setCurComp("signIn");
                            setSignInInputs({ username: "", password: "", email: "" });
                        }, children: "Back to Sign in" })] }) }) }));
};
RegisterSuccess.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};
export default RegisterSuccess;
