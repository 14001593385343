var LandingStyle = {
    NeuroGenCardsCap: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        // width: "60%",
        textAlign: "center",
        color: "#2e2e2e"
    },
    boldCaption: {
        //fontWeight: 'bold',
        fontSize: "1.5rem",
        paddingY: "1rem",
        color: "#2e2e2e"
    },
    contactTitleBox: {
        // width: "100%",
        display: 'flex',
        flexWrap: "wrap",
        alignItems: 'center',
        // justifyContent: "center",
        backgroundColor: ""
    },
    contactTitle: {
        fontWeight: 'bold',
        fontSize: "1.5rem",
        paddingY: "1rem",
        color: "#32c0e8",
        padding: "1rem",
    },
    contactCaption: {
        fontWeight: 'bold',
        fontSize: "1.1rem",
        paddingY: "1rem",
        color: "#2e2e2e"
        // padding: "1rem",
    },
    boxWithShadow: {
        borderRadius: "1rem",
        "-webkit-box-shadow": "0px 0px 12px 3px rgba(147,202,219,1)",
        "-moz-box-shadow": "0px 0px 12px 3px rgba(147,202,219,1)",
        "box-shadow": "0px 0px 12px 3px rgba(147,202,219,1)",
    }
};
export { LandingStyle };
