import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Grid, Checkbox, } from "@mui/material";
import SubjectsDatePicker from "./dateInputFilter";
var ExportTextCheckBox = function (_a) {
    var name = _a.name, label = _a.label, labelRed = _a.labelRed, handleChange = _a.handleChange, checkBoxType = _a.checkBoxType, checked = _a.checked, height = _a.height;
    //checkBoxType: alone, parent, child
    return (_jsxs(Grid, { container: true, item: true, display: "flex", flexWrap: "wrap", alignItems: "center", marginLeft: checkBoxType === "child" ? "2rem" : "", children: [_jsx(Grid, { item: true, children: _jsx(Checkbox, { name: name, onChange: function (e) {
                        handleChange(e.target.checked, name, checkBoxType);
                    }, checked: checked }) }), _jsx(Grid, { item: true, children: _jsx(Typography, { className: "small-text", children: label }) }), labelRed && (_jsx(Grid, { item: true, minHeight: "1rem", children: _jsx(Typography, { className: "small-text", style: { color: "red", paddingLeft: "10px", minHeight: "1rem", }, children: "(".concat(labelRed, ")") }) }))] }));
};
var ExportDateCheckBox = function (_a) {
    var name = _a.name, label = _a.label, dateFromName = _a.dateFromName, dateToName = _a.dateToName, dateFromLabel = _a.dateFromLabel, dateToLabel = _a.dateToLabel, handleChange = _a.handleChange, defaultFromDate = _a.defaultFromDate, defaulToDate = _a.defaulToDate, handleChangeDate = _a.handleChangeDate, checkBoxType = _a.checkBoxType, checked = _a.checked;
    //checkBoxType: alone, parent, child
    return (_jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", children: [_jsxs(Grid, { container: true, xs: 12, alignItems: "center", children: [_jsx(Checkbox, { name: name, onChange: function (e) {
                            handleChange(e.target.checked, name, checkBoxType);
                        }, checked: checked }), _jsx(Typography, { className: "small-text large-bold", sx: { marginRight: 2 }, children: label })] }), _jsx(Grid, { xs: 12, sx: { padding: "0 0.6rem" }, children: _jsx(SubjectsDatePicker, { name: dateFromName, label: dateFromLabel, minDate: new Date("2024-06-06") }) }), _jsx(Grid, { xs: 12, sx: { padding: "0 0.6rem" }, children: _jsx(SubjectsDatePicker, { name: dateToName, label: dateToLabel, minDate: new Date("2024-06-06") }) })] }));
};
export { ExportTextCheckBox, ExportDateCheckBox };
