// initial state
var initialState = {
    id: "",
    last_name: "",
    first_name: "",
    gender: "",
    education_level: "",
};
// action
var updateData = function (newData) { return ({
    type: 'updateSelectedSubjectListItem',
    payload: newData,
}); };
// reducer
var subjectListReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case 'updateSelectedSubjectListItem':
            return {
                state: state
            };
        default:
            return state;
    }
};
export { updateData, subjectListReducer };
