var ACLData = {};
// 1009 - new acl format
var acl = {
    "organization_normal": {
        "view": false, //BE
        "update": false, //ok
        "delete": false //ok
    },
    "organization_admin": {
        "create": false, //ok
        "view": false, //BE
        "update": false, //ok
        "delete": false //ok
    },
    "user_normal": {
        "create": false,
        "view": false, //BE
        "update": false, //ok
        "delete": false //ok
    },
    "user_admin": {
        "create": false,
        "view": false, //BE
        "update": false, //ok
        "delete": false //ok
    },
    "subject_normal": {
        "create": false,
        "view": false, //BE
        "update": false,
        "delete": false
    },
    "subject_admin": {
        "create": false,
        "view": false,
        "update": false,
        "delete": false
    },
    "user_group_normal": {
        "create": false, //ok
        "view": false, //BE
        "update": false, //ok (bug...)
        "delete": false //ok (bug...)
    },
    "user_group_admin": {
        "create": false, //ok
        "view": false, //BE
        "update": false, //ok
        "delete": false //ok
    },
    "data_export_normal": {
        "export": false,
        "export_retinad_probability_scores": false,
    },
    "data_export_admin": {
        "export": false,
        "export_retinad_probability_scores": false,
        /*  "veiw":false */
    },
    "dashboard_normal": {
        "view": false //ok
    },
    "dashboard_admin": {
        "view": false
    },
    "package_admin": {
        "create": false,
        "update": false,
        "delete": false,
    }
};
export { ACLData, acl };
