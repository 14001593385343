var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// @ts-nocheck
import * as React from "react";
var TranslateExamsClassHelper = /** @class */ (function (_super) {
    __extends(TranslateExamsClassHelper, _super);
    function TranslateExamsClassHelper(translate) {
        var _this = _super.call(this) || this;
        _this.addItem = function () {
            var newItem = "item".concat(_this.state.itemsExp.length + 1);
            // this.state.items = [...this.state.items, newItem];
            _this.setState({
                // items: [...this.state.items, newItem]
                itemsExp: _this.state.itemsExp.push(newItem)
            });
            return _this.state.itemsExp;
        };
        // translateEyeMuiChoices = (translate) => {
        //     this.setState({
        //         // items: [...this.state.items, newItem]
        //         EyeMuiChoices: [
        //             { value: 'Left', label: translate(`pages.exam.fields.params.eye.Left`) },
        //             { value: 'Right', label: translate(`pages.exam.fields.params.eye.Right`) },
        //             { value: 'Both', label: translate(`pages.exam.fields.params.eye.Both`) }
        //         ]
        //     });
        //     return this.state.EyeMuiChoices;
        // }
        _this.translateAllParams = function (translate) {
            _this.setState({
                // items: [...this.state.items, newItem]
                EyeMuiChoices: _this.state.EyeMuiChoices.map(function (el, idx) {
                    el.label = translate("pages.exam.fields.params.eye.".concat(el.value));
                }),
                AIModelMuiChoices: _this.state.AIModelMuiChoices.map(function (el, idx) {
                    el.label = translate("pages.exam.fields.params.ai_model.".concat(el.value));
                }),
                RiskOfAdMuiChoices: _this.state.RiskOfAdMuiChoices.map(function (el, idx) {
                    el.label = translate("pages.exam.fields.params.risk_of_ad.".concat(el.value));
                }),
                ExamStatusMuiChoices: _this.state.ExamStatusMuiChoices.map(function (el, idx) {
                    el.label = translate("pages.exam.fields.params.status.".concat(el.value));
                }),
            });
            return {
                eye: _this.state.EyeMuiChoices, ai_model: _this.state.AIModelMuiChoices,
                risk_of_ad: _this.state.RiskOfAdMuiChoices, status: _this.state.ExamStatusMuiChoices,
            };
        };
        _this.state = {
            itemsExp: ['item1', 'item2', 'item3'],
            EyeMuiChoices: [
                { value: 'Left', label: translate("pages.exam.fields.params.eye.Left") },
                { value: 'Right', label: translate("pages.exam.fields.params.eye.Right") },
                { value: 'Both', label: translate("pages.exam.fields.params.eye.Both") }
            ],
            AIModelMuiChoices: [
                { value: 'FP + OCT', label: 'FP + OCT' },
                { value: 'FP', label: 'FP' },
                { value: 'OCT', label: 'OCT' }
            ],
            RiskOfAdMuiChoices: [
                { value: 'High', label: 'High' },
                { value: 'Low', label: 'Low' },
            ],
            ExamStatusMuiChoices: [
                { value: 'Pending', label: 'Pending' },
                { value: 'In progress', label: 'In progress' },
                { value: 'Completed', label: 'Completed' },
            ]
        };
        return _this;
    }
    return TranslateExamsClassHelper;
}(React.Component));
;
var TranslateUsersOrgClassHelper = /** @class */ (function (_super) {
    __extends(TranslateUsersOrgClassHelper, _super);
    function TranslateUsersOrgClassHelper(translate) {
        var _this = _super.call(this) || this;
        _this.translateAllParams = function (translate) {
            _this.setState({
                // items: [...this.state.items, newItem]
                GenderMuiChoices: _this.state.GenderMuiChoices.map(function (el, idx) {
                    el.label = translate("params.subjects.".concat(el.value));
                }),
                StatusMuiChoices: _this.state.StatusMuiChoices.map(function (el, idx) {
                    el.label = translate("params.users.".concat(el.value));
                }),
            });
            return {
                gender: _this.state.GenderMuiChoices, status: _this.state.StatusMuiChoices,
            };
        };
        _this.state = {
            GenderMuiChoices: [
                { value: 'M', label: translate("params.subjects.M") },
                { value: 'F', label: translate("params.subjects.F") },
                { value: 'Prefer not to disclose', label: translate("params.subjects.Prefer not to disclose") },
            ],
            StatusMuiChoices: [
                { value: 'Active', label: translate("params.users.Active") },
                { value: 'Disabled', label: translate("params.users.Disabled") },
                { value: 'Inactive', label: translate("params.users.Not activate") },
            ]
        };
        return _this;
    }
    return TranslateUsersOrgClassHelper;
}(React.Component));
;
export { TranslateExamsClassHelper, TranslateUsersOrgClassHelper, };
