var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EditDialog from "./EditDialog";
import { DatagridConfigurable, ExportButton, 
// FilterButton,
List, SelectColumnsButton, TopToolbar, WrapperField, FunctionField, useDataProvider, useResourceContext, useNotify, useRefresh, useTranslate, useListController, Confirm, } from "react-admin";
// import dataProviderFactory from '../demoServerAndData/dataProvider';
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import PreviewIcon from "@mui/icons-material/Preview";
import { GenderMuiChoices, EducationLevelMuiChoices } from "./Params";
import { useMediaQuery, Divider, Typography, Grid, Button, IconButton, } from "@mui/material";
import { dbDateHelper } from "../utils/helper";
import { general } from "../utils/componentWizard";
import FilterList from "./FilterList";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateNewExamDates, } from "../utils/helper";
import SelectedRowLeftColumn from "../examsPage/SelectedRowLeftColumn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { acl } from "../utils/dummyData";
import { BODY_GRID_PADDING, NORMAL_GRID_SPACE, } from "../styles/styleConstants.data";
import { EducationLevelInArray } from "../coreData/MetaData";
import globalStyles from "../styles/globalStyles";
var ListActions = function () { return (_jsxs(TopToolbar, { children: [_jsx(SelectColumnsButton, {}), _jsx(ExportButton, {})] })); };
var SubjectList = function (props) {
    var _a, _b, _c;
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    var _e = useState(acl), acessControlList = _e[0], setAcessControlList = _e[1];
    var _f = useState({
    // analyzeFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // analyzeToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    }), filters = _f[0], setFilters = _f[1];
    var _g = useState({
        gender: GenderMuiChoices,
        educationLevel: EducationLevelMuiChoices,
    }), params = _g[0], setParams = _g[1];
    var educationLevelArray = EducationLevelInArray;
    var _h = useState([]), data = _h[0], setData = _h[1];
    var _j = useState(false), selectedColumn = _j[0], setSelectedColumn = _j[1];
    var _k = useState(), dialogData = _k[0], setDialogData = _k[1];
    var _l = useState(false), dialogOpened = _l[0], setDialogOpened = _l[1];
    var dataProvider = useDataProvider();
    var resource = useResourceContext(props);
    var notify = useNotify();
    var refresh = useRefresh();
    var navigate = useNavigate();
    var location = useLocation();
    // const listContext = useListContext();
    var _m = React.useState(10), rowsPerPage = _m[0], setRowsPerPage = _m[1];
    var translate = useTranslate();
    // for left panel
    var _o = useState(), selectedRow = _o[0], setSelectedRow = _o[1];
    var _p = useState(false), openLeftPanel = _p[0], setOpenLeftPanel = _p[1];
    var _q = useState([]), examsData = _q[0], setExamsData = _q[1];
    var sort = useListController().sort;
    // document.body.style.zoom = "100%";
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var isMobileScreen = useMediaQuery("(max-width: 750px)");
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newFilter, sessonStorageJSON, cachedAuth, settingsParams, newParams, orgParams, orgData;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    newFilter = __assign({}, filters);
                    //if is redirected with userId, set in filter
                    if (location.state) {
                        // newFilter.id = location.state.subjectId;
                        newFilter.customId = location.state.customId;
                    }
                    sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
                    if (sessonStorageJSON) {
                        setFilters(__assign(__assign({}, sessonStorageJSON), newFilter));
                    }
                    else {
                        setFilters(__assign({}, newFilter));
                    }
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, sessonStorageJSON), newFilter)));
                    refresh();
                    searchFilterGroup();
                    // get ACL
                    getACL();
                    cachedAuth = (_b = JSON.parse(localStorage.getItem("auth"))) !== null && _b !== void 0 ? _b : {};
                    settingsParams = {};
                    if (cachedAuth) {
                        settingsParams.id = cachedAuth.id;
                        dataProvider
                            .getOneWithoutDataJSON("users", settingsParams)
                            .then(function (rtnData) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                            var newACList = ((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.ACL) || {};
                            setAcessControlList(newACList);
                            localStorage.setItem("appBarOrg", (_e = (_d = (_c = (_b = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c['current_organization']) === null || _d === void 0 ? void 0 : _d[1]) !== null && _e !== void 0 ? _e : "N/A");
                            localStorage.setItem("userOrgId", (_j = (_h = (_g = (_f = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _f === void 0 ? void 0 : _f.info) === null || _g === void 0 ? void 0 : _g['current_organization']) === null || _h === void 0 ? void 0 : _h[0]) !== null && _j !== void 0 ? _j : "N/A");
                        })
                            .catch(function (error) {
                            // Handle any errors that occurred
                            console.error(error);
                            localStorage.setItem("appBarOrg", "N/A");
                            localStorage.setItem("userOrgId", "N/A");
                            notify(translate('error.ACL.getACLFailed'), {
                                type: 'error',
                            });
                        });
                    }
                    newParams = __assign({}, params);
                    orgParams = {
                        pagination: { page: 1, perPage: 200 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: {}
                    };
                    return [4 /*yield*/, dataProvider.getListNoFilter("organizations", orgParams)];
                case 1:
                    orgData = (_d.sent());
                    orgData = (_c = orgData === null || orgData === void 0 ? void 0 : orgData.data) === null || _c === void 0 ? void 0 : _c.map(function (item) { return ({ id: item.id, name: item.orgName, value: item.id }); });
                    newParams['organizations'] = orgData;
                    updateParams();
                    //update the education level with translated names
                    newParams['educationLevel'].map(function (el, idx) {
                        if (el.value === "Unknown" || el.value === "未知") {
                            el.label = translate("params.subjects.unkown");
                        }
                        else {
                            el.label = el.label.replace("yrs", translate("params.subjects.yrs")).replace("年", translate("params.subjects.yrs"));
                            // el.label = el.label.replace("年", translate(`params.subjects.yrs`))
                        }
                    });
                    newParams['gender'].map(function (el, idx) {
                        el.label = translate("params.subjects.".concat(el.value));
                    });
                    setParams(newParams);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    // update params with translated version
    var updateParams = function () {
        var newParams = __assign({}, params);
        //update the education level with translated names
        newParams['educationLevel'].map(function (el, idx) {
            if (el.value === "Unknown" || el.value === "未知") {
                el.label = translate("params.subjects.unkown");
            }
            else {
                el.label.replace("yrs", translate("params.subjects.yrs"));
            }
        });
        newParams['gender'].map(function (el, idx) {
            el.label = translate("params.subjects.".concat(el.value));
        });
        setParams(newParams);
    };
    useEffect(function () {
        updateParams();
    }, [translate]);
    var getCurUserACL = function () {
        var _a;
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        var settingsParams = {};
        if (cachedAuth) {
            settingsParams.id = cachedAuth.id;
            dataProvider
                .getOneWithoutDataJSON("users", settingsParams)
                .then(function (rtnData) {
                var _a;
                var rtnACL = __assign({}, (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.access);
                var newACList = __assign({}, acl);
                newACList.access = __assign({}, rtnACL);
                setAcessControlList(newACList);
                // resolve(rtnData.profile.info.current_organization[1])//return orgname
            })
                .catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
            });
        }
    };
    useEffect(function () {
    }, [selectedRow]);
    useEffect(function () {
        refresh();
    }, [filters]);
    // filter group when "search"
    var searchFilterGroup = function () {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        // update the filter labels
        // let updatedFilters = JSON.parse(JSON.stringify(filters));
        // if (updatedFilters?.educationLevel) {
        //     updatedFilters.educationLevel = EducationLevelMuiChoices[filters.educationLevel].label
        // }
        // if (updatedFilters?.gender) {
        //     updatedFilters.gender = GenderMuiChoices[filters.gender].label
        // }
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "updatedAt", order: "ASC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: __assign(__assign({}, filters), sessonStorageJSON),
        };
        dataProvider
            .getList(resource, params)
            .then(function (data) {
            refresh();
            notify(translate("pages.subject.notify.get"));
            setIsLoading(false);
        })
            .catch(function (error) {
            console.log('error==', error);
            notify(translate("pages.subject.notify.getFailed"), {
                type: 'error',
            });
            setIsLoading(false);
        });
    };
    var closeDialog = function () {
        setDialogOpened(false);
    };
    var setDialogDataAction = function (record) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        var translatedYrs = (_a = record === null || record === void 0 ? void 0 : record.educationLevel) !== null && _a !== void 0 ? _a : null;
        switch (translatedYrs) {
            case ("Unknown" || "未知"):
                translatedYrs = translate("params.subjects.unkown");
                break;
            case null:
                break;
            default:
                translatedYrs = translatedYrs.replace("yrs", translate("params.subjects.yrs"));
                break;
        }
        setDialogData({
            submit: null,
            dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create",
            id: (_b = record === null || record === void 0 ? void 0 : record.id) !== null && _b !== void 0 ? _b : null,
            customId: (_c = record === null || record === void 0 ? void 0 : record.customId) !== null && _c !== void 0 ? _c : null,
            lastname: (_d = record === null || record === void 0 ? void 0 : record.lastname) !== null && _d !== void 0 ? _d : null,
            firstname: (_e = record === null || record === void 0 ? void 0 : record.firstname) !== null && _e !== void 0 ? _e : null,
            gender: (_f = record === null || record === void 0 ? void 0 : record.gender) !== null && _f !== void 0 ? _f : null,
            // gender: record?.gender ? translate(`params.subjects.${record.gender}`) : null,
            // educationLevel: translatedYrs ?? null,
            educationLevel: (_g = record === null || record === void 0 ? void 0 : record.educationLevel) !== null && _g !== void 0 ? _g : null,
            dateOfBirth: (_h = record === null || record === void 0 ? void 0 : record.dateOfBirth) !== null && _h !== void 0 ? _h : null,
            editOrganizations: (_k = (_j = record === null || record === void 0 ? void 0 : record.organization) === null || _j === void 0 ? void 0 : _j.id) !== null && _k !== void 0 ? _k : null,
            orgName: (_m = (_l = record === null || record === void 0 ? void 0 : record.organization) === null || _l === void 0 ? void 0 : _l.orgName) !== null && _m !== void 0 ? _m : null,
        });
        //console.log(record.dateOfBirth, "record.dateOfBirth");
    };
    //edit org action
    var openEditDialog = function (record) {
        //open dialog
        // set logic according to ACL
        setDialogDataAction(record);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    //create org action
    var openCreateEditDialog = function () {
        //open dialog
        // set logic according to ACL
        var newRecord = {};
        var cachedUserOrgId = localStorage.getItem("userOrgId");
        // if (acessControlList?.subject_admin?.create === false) {
        newRecord.editOrganizations = cachedUserOrgId;
        newRecord.organization = {};
        newRecord.dialogType = "Create";
        newRecord.organization.id = cachedUserOrgId;
        // }
        setDialogDataAction(newRecord);
        // formik?.current?.resetForm();
        setDialogOpened(true);
    };
    var reloadRecord = function (values, dialogType) {
        try {
            var subParams = {
                data: {
                    // id: values.id,
                    customId: values.customId, //[back] later switch to customId
                    firstname: values.firstname,
                    lastname: values.lastname,
                    gender: values.gender,
                    educationLevel: values.educationLevel,
                    dateOfBirth: values.dateOfBirth,
                    createby: 1,
                    givenId: values.id, //[back] later switch to real id - id for db, not customId
                    organizationIds: [values.editOrganizations],
                },
                sort: { field: "updatedAt", order: "ASC" },
            };
            // check if is current user
            // const newACL = getCurUserACL();
            // const newACL = getACL();
            // updateOrgAtAppBar(newACL);
            switch (dialogType) {
                case "Create":
                    var updatedData = JSON.parse(JSON.stringify(data));
                    updatedData.push(values);
                    setData(updatedData);
                    dataProvider
                        .create(resource, subParams)
                        .then(function (returnData) {
                        refresh();
                        notify(translate("pages.subject.notify.added"));
                        closeDialog();
                        setDialogData(__assign({}, values));
                    })
                        .catch(function (error) {
                        // Handle any errors that occurred
                        console.log('error==', error);
                        if (error == "HttpError2: A subject with this id already exists in this organization. Please use a unique subject id" ||
                            error == "HttpError2: There is same id subject in your organization") {
                            notify(translate("error.subject.subId"), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: Unauthorized") {
                            notify(translate("error.ACL.unauthorized"), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: This organization id does not belong to any organizations") {
                            notify(translate("error.subject.org"), {
                                type: 'error',
                            });
                        }
                        else {
                            notify(translate("pages.subject.notify.addFailed"), {
                                type: 'error',
                            });
                        }
                    });
                    break;
                case "Edit":
                    subParams.data.id = values.id;
                    dataProvider
                        .update(resource, subParams)
                        .then(function () {
                        refresh();
                        getACL();
                        notify(translate("pages.subject.notify.updated"));
                        closeDialog();
                        setDialogData(__assign({}, values));
                    })
                        .catch(function (error) {
                        // Handle any errors that occurred
                        console.log('error==', error);
                        if (error == "HttpError2: A subject with this id already exists in this organization. Please use a unique subject id" ||
                            error == "HttpError2: There is same id subject in your organization") {
                            notify(translate("error.subject.subId"), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: This organization id does not belong to any organizations") {
                            notify(translate("error.subject.org"), {
                                type: 'error',
                            });
                        }
                        else if (error == "HttpError2: Unauthorized") {
                            notify(translate("error.ACL.unauthorized"), {
                                type: 'error',
                            });
                        }
                        else if (error.message.includes("not found")) {
                            notify(translate("error.subject.NotFoundException"), {
                                type: 'error',
                            });
                        }
                        else {
                            notify(translate("pages.subject.notify.updateFailed"), {
                                type: 'error',
                            });
                        }
                    });
                    break;
            }
        }
        catch (err) {
            console.log(err, "==err");
            // dispatch(actionTypes.SNACKBAR_ERROR);
            if (err == "HttpError2: Unauthorized") {
                notify(translate("error.ACL.unauthorized"), {
                    type: 'error',
                });
            }
            else {
                notify(translate("error.common.tryAgain"), {
                    type: 'error',
                });
            }
        }
    };
    // get ACL for org (top bar)
    var getACL = function () {
        var _a;
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        var settingsParams = {};
        if (cachedAuth) {
            settingsParams.id = cachedAuth.id;
            dataProvider
                .getOneWithoutDataJSON("users", settingsParams)
                .then(function (rtnData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                localStorage.setItem("appBarOrg", (_d = (_c = (_b = (_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b['current_organization']) === null || _c === void 0 ? void 0 : _c[1]) !== null && _d !== void 0 ? _d : "N/A");
                localStorage.setItem("userOrgId", (_h = (_g = (_f = (_e = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _e === void 0 ? void 0 : _e.info) === null || _f === void 0 ? void 0 : _f['current_organization']) === null || _g === void 0 ? void 0 : _g[0]) !== null && _h !== void 0 ? _h : "N/A");
                setAcessControlList((_j = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _j === void 0 ? void 0 : _j.ACL);
                refresh();
            })
                .catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
            });
        }
    };
    var handleCloseLeftPanel = function () {
        setOpenLeftPanel(false);
    };
    var handleListChange = function () {
    };
    // for tab size handling paginzation
    var handleChangeRowsPerPage = function (event) {
        var newRowsPerPage = event.target.value;
        setRowsPerPage(newRowsPerPage);
        sessionStorage.setItem("".concat(window.location.pathname, "_tabSize_perPage"), newRowsPerPage);
        refresh();
    };
    return (_jsxs(Grid, { container: true, padding: BODY_GRID_PADDING, columnGap: NORMAL_GRID_SPACE, rowGap: 2, direction: { xs: "row", lg: "column" }, children: [openLeftPanel && (_jsx(Grid, { container: true, xs: 12, lg: 3, sx: {
                    // position: 'sticky',
                    top: 20,
                    height: 'fit-content'
                }, children: _jsx(SelectedRowLeftColumn, { selectedRow: dialogData, setSelectedRow: setDialogData, data: examsData, examsData: examsData, setDialogOpened: setDialogOpened, openExamEditDialog: openEditDialog, closeLeftPanel: handleCloseLeftPanel, page: resource }) })), _jsxs(Grid, { container: true, xs: 12, lg: openLeftPanel ? 9 : 12, alignContent: "start", justifyItems: "baseline", direction: "row", rowGap: 1, children: [_jsx(Grid, { xs: 12, children: _jsx(general.breadcrumb, { trail: [
                                translate("breadcrumb.main"),
                                translate("pages.subject.name"),
                            ] }) }), _jsxs(Grid, { xs: 12, display: "flex", alignItems: "center", justifyContent: "start", direction: "row", columnGap: NORMAL_GRID_SPACE, children: [_jsx(Grid, { children: _jsx(Typography, { className: "normal-text large-bold", children: translate("pages.subject.name") }) }), (((_a = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_normal) === null || _a === void 0 ? void 0 : _a.create) || ((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_admin) === null || _b === void 0 ? void 0 : _b.create)) && (_jsx(Grid, { children: _jsx(Button, { variant: "contained", startIcon: _jsx(AddCircleOutlineIcon, {}), onClick: openCreateEditDialog, children: translate("pages.subject.addNew") }) }))] }), _jsx(Grid, { xs: 12, children: _jsx(Divider, {}) }), _jsx(Grid, { xs: 12, children: _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }) }), _jsxs(Grid, { xs: 12, children: [_jsx(TableDatagrid, { isTabScreen: isTabScreen, acessControlList: acessControlList, translate: translate, setSelectedRow: setSelectedRow, data: data, setData: setData, setSelectedColumn: setSelectedColumn, selectedColumn: selectedColumn, dialogData: dialogData, setDialogData: setDialogData, dialogOpened: dialogOpened, setDialogOpened: setDialogOpened, closeDialog: closeDialog, educationLevelArray: educationLevelArray, openEditDialog: openEditDialog, dataProvider: dataProvider, refresh: refresh, notify: notify, resource: resource, navigate: navigate, sort: sort, setDialogDataAction: setDialogDataAction, setOpenLeftPanel: setOpenLeftPanel, setExamsData: setExamsData, filters: filters, handleListChange: handleListChange }), openLeftPanel && (_jsx(Grid, { xs: 24, display: "flex", justifyContent: "end", children: _jsx(Button, { variant: "contained", onClick: function () {
                                        navigate("/admin/exams", { state: dialogData });
                                    }, className: "button-all", children: translate("pages.common.selection.select") }) }))] })] }), dialogOpened && (_jsx(EditDialog, { disableEditOrg: ((_c = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_admin) === null || _c === void 0 ? void 0 : _c.create) === false && dialogData.dialogType === "Create", opened: dialogOpened, data: dialogData, params: params, closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate, isMobileScreen: isMobileScreen }))] }));
};
// const orderFilters = [
//     // <SearchInput source="q" alwaysOn />,
//     <ReferenceInput source="id" reference="id" />,
//     <TextField source="lastname" label="Last name" />,
//     <TextField source="firstname" label="First name" />,
//     <TextField source="gender" label="Gender" />,
//     <TextField source="educationLevel" label="Education lv." />,
//     <DateField source="dateOfBirth" label="Date of birth" />,
//     <NullableBooleanInput source="returned" />,
// ];
var TableDatagrid = function (_a) {
    var _b, _c, _d, _e;
    var acessControlList = _a.acessControlList, translate = _a.translate, setSelectedRow = _a.setSelectedRow, data = _a.data, setData = _a.setData, setSelectedColumn = _a.setSelectedColumn, selectedColumn = _a.selectedColumn, setDialogData = _a.setDialogData, dialogData = _a.dialogData, dialogOpened = _a.dialogOpened, setDialogOpened = _a.setDialogOpened, setDialogDataAction = _a.setDialogDataAction, closeDialog = _a.closeDialog, educationLevelArray = _a.educationLevelArray, openEditDialog = _a.openEditDialog, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, sort = _a.sort, setOpenLeftPanel = _a.setOpenLeftPanel, setExamsData = _a.setExamsData, isTabScreen = _a.isTabScreen, filters = _a.filters, handleListChange = _a.handleListChange;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down("sm");
    });
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        // backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
    }); };
    var dispatch = useDispatch();
    var handleDataTableChange = function () {
    };
    var delAction = function (record) {
        var params = {
            data: {
                id: record.id,
                customId: record.customId, //[back] later switch to customId
                firstname: record.firstname,
                lastname: record.lastname,
                gender: record.gender,
                educationLevel: record.educationLevel,
                dateOfBirth: record.dateOfBirth,
                createby: 1,
                givenId: record.id, //[back] later switch to real id - id for db, not customId
            },
        };
        dataProvider.delete(resource, params).then(function () {
            refresh();
            notify(translate("pages.subject.notify.deleted"));
        }).catch(function (error) {
            // Handle any errors that occurred
            notify(translate("error.subject.delFailed"), {
                type: 'error',
            });
        });
    };
    var _f = useState(false), confirmOpen = _f[0], setConfirmOpen = _f[1];
    var _g = useState(null), recordToDelete = _g[0], setRecordToDelete = _g[1];
    var handleDelete = function (record) {
        setRecordToDelete(record);
        setConfirmOpen(true);
    };
    var handleConfirm = function () {
        if (recordToDelete) {
            delAction(recordToDelete);
        }
        setConfirmOpen(false);
    };
    var handleCancel = function () {
        setConfirmOpen(false);
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, { sx: { width: '100%', overflowX: 'auto' }, children: _jsx(List, { filterDefaultValues: filters, sort: { field: "updatedAt", order: "DESC" }, debounce: handleListChange, empty: false, actions: _jsx(ListActions, {}), children: _jsxs(DatagridConfigurable, { style: { tableLayout: "fixed", minWidth: '100%' }, sx: {
                            '& .RaDatagrid-row': rowStyle,
                            '& .RaDatagrid-headerCell': rowHeaderStyle,
                            '& .RaDatagrid-rowCell': rowCellStyle,
                            '& @media (max-width:960px)': {
                                '.makeStyles-caption-2[id]': {
                                    display: "block"
                                },
                                '& @media (max-width: 768px)': {
                                    '.MuiTablePagination-actions': {
                                        display: "flex",
                                        alignItems: "center",
                                    }
                                }
                            },
                        }, initialState: {
                            pagination: {
                                paginationModel: { pageSize: 10 }
                            }
                        }, rowStyle: postRowStyle, bulkActionButtons: false, onChange: handleDataTableChange, rowClick: function (id, resource, record) {
                            setDialogDataAction(record);
                            setExamsData(updateNewExamDates(record === null || record === void 0 ? void 0 : record.exams));
                            console.log("==logggg", record);
                            setOpenLeftPanel(true);
                            localStorage.setItem("hkid_id", record.id);
                            localStorage.setItem("customId", record.customId);
                        }, children: [_jsx(FunctionField, { source: "customId", label: translate("pages.subject.fields.customId"), render: function (record) {
                                    return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                            _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.customId"), ":"] }), _jsx(Typography, { className: "small-text", children: record.customId })] })
                                            : record.customId }));
                                } }), _jsx(FunctionField, { source: "organization", label: translate("pages.userList.fields.organization"), render: function (record) {
                                    var _a, _b;
                                    return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                            _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.userList.fields.organization"), ":"] }), _jsx(Typography, { className: "small-text", children: (_a = record.organization) === null || _a === void 0 ? void 0 : _a.orgName })] })
                                            : (_b = record.organization) === null || _b === void 0 ? void 0 : _b.orgName }));
                                } }), _jsx(FunctionField, { source: "lastname", label: translate("pages.subject.fields.lastname"), render: function (record) {
                                    return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                            _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.lastname"), ":"] }), _jsx(Typography, { className: "small-text", children: record.lastname })] })
                                            : record.lastname }));
                                } }), _jsx(FunctionField, { source: "firstname", label: translate("pages.subject.fields.firstname"), render: function (record) {
                                    return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                            _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.firstname"), ":"] }), _jsx(Typography, { className: "small-text", children: record.firstname })] })
                                            : record.firstname }));
                                } }), _jsx(FunctionField, { source: "gender", label: translate("pages.subject.fields.gender"), render: function (record) {
                                    return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                            _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.gender"), ":"] }), _jsx(Typography, { className: "small-text", children: translate("params.subjects.".concat(record.gender)) })] })
                                            : translate("params.subjects.".concat(record.gender)) }));
                                } }), _jsx(FunctionField, { source: "dateOfBirth", label: translate("pages.subject.fields.dateOfBirth"), render: function (record) {
                                    return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                            _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.dateOfBirth"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record === null || record === void 0 ? void 0 : record.dateOfBirth) })] })
                                            : dbDateHelper(record === null || record === void 0 ? void 0 : record.dateOfBirth) }));
                                } }), _jsx(FunctionField, { source: "educationLevel", label: translate("pages.subject.fields.educationLevel"), render: function (record) {
                                    var bgColor;
                                    var newEducationLevel = record.educationLevel.replace("yrs", "");
                                    var afterText = translate("params.subjects.yrs");
                                    switch (record.educationLevel) {
                                        case educationLevelArray[0]:
                                            bgColor = "#aad5fd";
                                            break;
                                        case educationLevelArray[1]:
                                            bgColor = "#6bb0eb";
                                            break;
                                        case educationLevelArray[2]:
                                            bgColor = "#3d7ebe";
                                            break;
                                        case educationLevelArray[3]:
                                            bgColor = "#0d5da8";
                                            break;
                                        case educationLevelArray[4]:
                                            bgColor = "#004587";
                                            break;
                                        case educationLevelArray[5]:
                                            bgColor = "#3d648a";
                                            newEducationLevel = translate("params.subjects.unkown");
                                            afterText = "";
                                            break;
                                    }
                                    var updatedEduLvl = newEducationLevel + afterText;
                                    return (_jsxs("div", { style: globalStyles.centerContainer, children: [isTabScreen ?
                                                _jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.educationLevel"), ":"] })
                                                : _jsx(_Fragment, {}), _jsx(Button, { disabled: true, className: "small-text-button", sx: {
                                                    backgroundColor: bgColor,
                                                }, children: updatedEduLvl })] }));
                                } }), _jsx(WrapperField, { label: translate("pages.subject.fields.actions"), children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                                return (_jsx(Grid, { item: true, children: _jsx(Tooltip, { title: translate("tooltip.viewSubject"), children: _jsx(IconButton, { size: "small", "aria-label": "preview", color: "inherit", children: _jsx(PreviewIcon, { fontSize: "small" }) }) }) }));
                                            } }), (((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_admin) === null || _b === void 0 ? void 0 : _b.update) || ((_c = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_normal) === null || _c === void 0 ? void 0 : _c.update)) && (_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                                var _a, _b, _c, _d;
                                                var appBarOrg = (_a = localStorage.getItem("appBarOrg")) !== null && _a !== void 0 ? _a : "";
                                                return (_jsx(Grid, { item: true, onClick: function (e) {
                                                        e.stopPropagation();
                                                        openEditDialog(record);
                                                    }, children: ((((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_normal) === null || _b === void 0 ? void 0 : _b.update) === true && appBarOrg === ((_c = record === null || record === void 0 ? void 0 : record.organization) === null || _c === void 0 ? void 0 : _c.orgName)) || ((_d = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_admin) === null || _d === void 0 ? void 0 : _d.update)) &&
                                                        _jsx(Tooltip, { title: translate("tooltip.editSubject"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(EditIcon, { fontSize: "small" }) }) }) }));
                                            } })), (((_d = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_admin) === null || _d === void 0 ? void 0 : _d.delete) || ((_e = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_normal) === null || _e === void 0 ? void 0 : _e.delete)) && (_jsx(FunctionField, { source: "", label: "", render: function (record) {
                                                var _a, _b, _c, _d;
                                                var appBarOrg = (_a = localStorage.getItem("appBarOrg")) !== null && _a !== void 0 ? _a : "";
                                                return (_jsx(Grid, { item: true, onClick: function (e) {
                                                        e.stopPropagation();
                                                        handleDelete(record);
                                                    }, children: ((((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_normal) === null || _b === void 0 ? void 0 : _b.delete) === true && appBarOrg === ((_c = record === null || record === void 0 ? void 0 : record.organization) === null || _c === void 0 ? void 0 : _c.orgName)) || ((_d = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.subject_admin) === null || _d === void 0 ? void 0 : _d.delete)) && _jsx(Tooltip, { title: translate("tooltip.deleteSubject"), children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(DeleteIcon, { fontSize: "small" }) }) }) }));
                                            } }))] }) })] }) }) }), _jsx(Confirm, { isOpen: confirmOpen, title: translate("tooltip.deleteSubject"), content: translate("comfirmDialog.subject"), onConfirm: handleConfirm, onClose: handleCancel })] }));
};
export default SubjectList;
