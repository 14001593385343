var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardActions, CircularProgress, Grid, Typography, } from "@mui/material";
import { required, TextInput, useTranslate, } from "react-admin";
import Box from "@mui/material/Box";
// import LoginLogo from '../assets/logos/login logo.png'
// import ICSLogo from "../../assets/logos/ics logo.png";
// import ICSLogo from '../../assets/logos/logo-design1a5-with-text-white.png'
import ICSLogo from "../../assets/logos/iCogSci Logo_Design 1a-5_v2.0(241209)-04resize.png";
import "../../styles/global.module.css";
var ForgetPW = function (_a) {
    var loading = _a.loading, notify = _a.notify, setSignInInputs = _a.setSignInInputs, signInInputs = _a.signInInputs, setCurComp = _a.setCurComp, handleForgetPW = _a.handleForgetPW, setConfirmForget = _a.setConfirmForget, confirmForget = _a.confirmForget, isMobileScreen = _a.isMobileScreen, isTabScreen = _a.isTabScreen;
    var translate = useTranslate();
    var cardWidth = "40%";
    switch (true) {
        case isMobileScreen:
            cardWidth = "75%";
            break;
        case isTabScreen:
            cardWidth = "60%";
            break;
    }
    return (_jsx(Box, { sx: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            background: "#ebeff2",
            backgroundSize: "cover",
        }, children: _jsxs(Card, { sx: {
                width: cardWidth,
                maxWidth: "27rem",
                marginTop: "3em",
                borderRadius: "40px",
                boxShadow: "20px 20px 25px rgba(77, 106, 149, 0.5)", // Light blue color
            }, children: [_jsx(Box, { sx: {
                        margin: "1em",
                        display: "flex",
                        justifyContent: "center",
                    }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 135, style: {
                            transition: "transform 0.8s ease-in-out",
                        }, onMouseEnter: function (e) {
                            e.currentTarget.style.transform = "scale(2)";
                        }, onMouseLeave: function (e) {
                            e.currentTarget.style.transform = "scale(1)";
                        } }) }), _jsx(Box, { sx: {
                        marginTop: "1em",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        color: function (theme) { return theme.palette.grey[500]; },
                    }, children: _jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", style: { backgroundColor: "" }, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: " large-bold", textAlign: "center", color: "black", style: {
                                        fontSize: "1.3rem"
                                    }, children: translate("login.AI_Screening_Platform") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: " normal-bold", textAlign: "center", color: "black", style: {
                                        fontSize: "1.2rem"
                                    }, children: translate("login.ForgetPassword") }) }), !confirmForget && (_jsx(Grid, { item: true, xs: 10, children: _jsx(Typography, { className: " no-bold", textAlign: "center", color: "black", sx: {
                                        marginTop: "1.5em",
                                        fontSize: "1rem"
                                    }, children: translate("login.forgetPW.caption.willSendEmail") }) })), confirmForget && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 11, paddingY: 1, children: _jsx(Typography, { className: "small-text large-bold", textAlign: "start", color: "black", sx: { marginTop: "1em", marginLeft: "1em", opacity: 0.5 }, children: translate("login.forgetPW.caption.sentEmail") }) }), _jsx(Grid, { item: true, xs: 11, paddingY: 3, children: _jsx(Typography, { className: "small-text large-bold", textAlign: "start", color: "black", sx: { marginLeft: "1em", opacity: 0.5 }, children: translate("login.forgetPW.caption.notReceiveEmail") }) })] }))] }) }), !confirmForget && (_jsx(Box, { sx: { padding: "0 1em 1em 1em" }, children: _jsxs(Box, { sx: { marginTop: "1em" }, children: [_jsx(Typography, { className: "small-text large-bold", color: "#4d6a95", sx: { margin: "0px 0px 0px 10px" }, children: translate("login.email") }), _jsx(TextInput, { variant: "outlined", autoFocus: true, source: "email", label: false, onChange: function (e) {
                                    setSignInInputs(__assign(__assign({}, signInInputs), { email: e.target.value }));
                                }, disabled: loading, validate: required(), fullWidth: true, sx: {
                                    "& .MuiInputBase-root": {
                                        borderRadius: "10px",
                                        height: "3rem",
                                        border: "1px solid #ccc",
                                    },
                                } })] }) })), _jsx(CardActions, { sx: { padding: "0 1em 1em 1em" }, children: _jsxs(Button, { className: "normal-text login-button", variant: "contained", color: "primary", disabled: loading, fullWidth: true, onClick: function () {
                            if (confirmForget) {
                                // If we're in the "confirm" state, resend the email without validation
                                handleForgetPW();
                            }
                            else if (signInInputs.email) {
                                // If we're not in the "confirm" state and have a username, send the initial email
                                handleForgetPW();
                            }
                            else {
                                // If we don't have a username, show an error
                                notify(translate('error.login.emailFieldEmpty'), {
                                    type: 'error',
                                });
                            }
                        }, children: [loading && _jsx(CircularProgress, { size: 25, thickness: 2 }), confirmForget
                                ? translate("login.resend")
                                : translate("common.confirm")] }) }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", paddingY: 2, children: _jsx(Typography, { className: "small-text large-bold", textAlign: "center", color: "#4d6a95", style: { cursor: "pointer" }, fontSize: "0.85em", sx: { margin: "0px 0px 50px 25px" }, onClick: function () {
                            setConfirmForget(false);
                            setSignInInputs({ username: "", password: "", email: "" });
                            setCurComp("signIn");
                        }, children: translate("login.BackToSignIn") }) })] }) }));
};
export default ForgetPW;
